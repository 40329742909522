import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useHistory } from "react-router-dom";
import "./index.scss";
// import { getArrayHinges } from './AddHinge';

const Hinge = ({
  preview,
  sku,
  filling,
  open,
  size,
  add_hinge_boolean,
  changeStateHinge,
  resetHinge,
  ...props
}) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    resetHinge();
  }, []);

  const toNextStep = () => {
    if (add_hinge_boolean && open !== "Slider" && open !== "Avento") {
      let isInvalid = document.querySelectorAll(".is-invalid").length;
      let { hinges, match } = props;
      let section = ["A", "B", "C", "D", "E"];
      if (isInvalid === 0) {
        let dimention = "";
        hinges.forEach((value, key) => {
          let section_key = section[value.order - 4];
          if (value.order === 8) {
            let { open, width, height } = match.params;
            let size = parseInt(
              open === "Right" || open === "Left" ? height : width
            );
            dimention =
              key === 0
                ? `${size - value.sizeHinge}|${section_key}`
                : `${dimention}x${size - value.sizeHinge}|${section_key}`;
          } else {
            dimention =
              key === 0
                ? `${value.sizeHinge}|${section_key}`
                : `${dimention}x${value.sizeHinge}|${section_key}`;
          }
        });
        history.push(`${location.pathname}hinge/${dimention}/`);
      } else {
        toast.warn(`Hay ${isInvalid} campos con medidas incorrectas`);
      }
    } else {
      history.push(`${location.pathname}hinge/noHinges/`);
    }
  };

  const lastStep = () => {
    history.push(`${location.pathname.split("/profile")[0]}`);
  };

  const noHinges = () => {
    changeStateHinge(!add_hinge_boolean);
    resetHinge();
  };

  const renderTitle = () => {
    if (open === "Avento" || open === "Slider")
      return "Vista previa del esquema de tu puerta";
    return "Elige la posición de la bisagra";
  };

  return (
    <Row className='justify-content-center w-100'>
      <Col className='preview-body col-12'>{preview()}</Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  hinges: state.hinges.hinge_position,
  add_hinge_boolean: state.hinges.add_hinge_boolean,
  sku: state.sash.sku,
  filling: state.filling,
  open: state.open,
  size: state.size,
});

const mapDispatchToProps = (dispach) => ({
  resetHinge() {
    dispach({
      type: "RESET_STATE",
    });
  },
  changeStateHinge(add_hinge_boolean) {
    dispach({
      type: "CHANGE_ADD_HINGE",
      add_hinge_boolean,
    });
  },
});

const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(Hinge);

export default component;

// query={{getArrayHinges: getArrayHinges()}
