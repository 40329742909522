const defaultState = ''

function reducer(state = defaultState, {type, side}) {
  let stateValue = '';
  switch (type) {
    case 'CHANGE_SIDE': {
      stateValue = side
      break;
    }
    case 'RESET_OPEN': {
      stateValue = ''
      break;
    }
    default:
      stateValue = state;
      break;
  }
  return stateValue
}

export default reducer;