const defaultState = ''

function reducer(state = defaultState, {type, change_state}) {
  let stateValue = '';
  switch (type) {
    case 'CHANGE_STATE_SELECT_COMMUNE': {
      stateValue = change_state
      break;
    }
    default:
      stateValue = state;
      break;
  }
  return stateValue
}

export default reducer;