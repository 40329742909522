const defaultState = null;

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "CHANGE_MECHANIZED": {
      return {
        ...state,
        ...payload.mechanized,
      };
    }
    case "RESET_MECHANIZED": {
      return defaultState;
    }
    default:
      return state;
  }
}

export default reducer;
