import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./index.css";
import { connect } from "react-redux";
import { getOptions, getInitialOption } from "./options";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const Mechanized = ({
  mechanized,
  setMechanized,
  dimensions,
  open,
  resetMechanized,
  sash,
  ...props
}) => {
  console.log("sash", sash, "open", open, "dimensions", dimensions, "mechanized", mechanized, "props", props);
  const [mechanizedSelected, setMechanizedSelected] = useState(mechanized);
  const [mechanizedOptions, setmechanizedOptions] = useState([]);
  const changeMechanized = (mechanized) => {
    setMechanizedSelected(mechanized);
    setMechanized(mechanized);
    // validateMechanized(mechanized)
  };

  // const validateMechanized = (mechanized) =>{
  //   const submitButton = document.querySelector('.btn-next')
  //   const mechanizedValue = mechanized.value.split('_')[0]

  //   submitButton.disabled = validateSizesMechanize(mechanizedValue)
  // }

  const validateSizesMechanize = (mechanized) => {
    let width = dimensions.width;
    let height = dimensions.height;
    let minimum = 150;
    if (open === "Top" || open === "Bottom" || open === "Avento") {
      width = dimensions.height;
      height = dimensions.width;
    }
    switch (mechanized) {
      case "OMNIA":
        return height > 2400 || width > 500 || (height || width) < minimum;
      case "BLUEMOTION":
        return height > 2400 || width > 500 || (height || width) < minimum;
      default:
        return height > 2400 || width > 600 || (height || width) < minimum;
    }
  };

  useEffect(() => {
    const totalSize = parseInt(dimensions.width) + parseInt(dimensions.height);
    var newOptions = getOptions(
      open,
      totalSize,
      dimensions.height,
      dimensions.width
    );
    console.log("new options ====>", mechanized);
    if(open === "Avento"){
      changeMechanized(newOptions[0]);
    }
    const names = sash.name.split(" ");
    console.log("open", open);
    if ((names.includes("OFF") || names.includes("STYLO")) && open !== "Avento" && open !== "Slider") {
      newOptions = [];
      newOptions.push({
        label: "Style/off",
        disabled: false,
        value: "35MM_HINGE",
      });
      newOptions.push({
        label: "Sin mecanizado",
        disabled: false,
        value: "NO_MECHANIZED",
      });
    } else if ((names.includes("OMNIA") || names.includes("MINI")) && open !== "Avento" && open !== "Slider" && open !== "Top" && open !=="Bottom") {
      newOptions = [];
      newOptions.push({
        label: "Omnia/mini",
        disabled: false,
        value: "OMNIA_HINGE",
      });
      newOptions.push({
        label: "Omnia/mini con blumotion",
        disabled: false,
        value: "BLUEMOTION_HINGE",
      });
      newOptions.push({
        label: "Sin mecanizado",
        disabled: false,
        value: "NO_MECHANIZED",
      });
    } else if (open === 'Top') {
      newOptions = [];
      // let maxWidth;
      // if(dimensions.height === 600){
        //   maxWidth = 700
        // }else if(dimensions.height === 500) {
          //   maxWidth = 900
          // } else{
            //   maxWidth = 700
      // }
      newOptions.push({
        label: "Omnia/mini",
        disabled: false,
        value: "OMNIA_HINGE",
      });
      newOptions.push({
        label: "Omnia/mini con blumotion",
        disabled: false,
        value: "BLUEMOTION_HINGE",
      });
      newOptions.push({
        label: "Bisagra omnia y HK-XS",
        disabled: dimensions.height >= 300 && dimensions.height <= 600 && dimensions.width >= 400 && dimensions.width <= 900 ? false : true ,
        value: "HK_XS",
      });
      newOptions.push({
        label: "Omnia/mini y HK-XS con Blumotion",
        disabled: dimensions.height >= 300 && dimensions.height <= 600 && dimensions.width >= 400 && dimensions.width <= 900 ? false : true ,
        value: "HK_XSBLUEMOTION",
      });
      newOptions.push({
        label: "Sin mecanizado",
        disabled: false,
        value: "NO_MECHANIZED",
      });
      /*
      newOptions.push({
        label: "HK-TOP",
        disabled: dimensions.height >= 300 && dimensions.height <= 600 && dimensions.width >= 400 && dimensions.width <= 1200 ? false : true ,
        value: "HK_TOP",
      });
      */
    } else if (open === 'Bottom') {
      newOptions = [];
      // let maxWidth;
      // if(dimensions.height === 600){
        //   maxWidth = 700
        // }else if(dimensions.height === 500) {
          //   maxWidth = 900
          // } else{
            //   maxWidth = 700
      // }
      newOptions.push({
        label: "Omnia/mini",
        disabled: false,
        value: "OMNIA_HINGE",
      });
      newOptions.push({
        label: "Omnia/mini con blumotion",
        disabled: false,
        value: "BLUEMOTION_HINGE",
      });
      newOptions.push({
        label: "Omnia/mini y HK-XS",
        disabled: dimensions.height >= 300 && dimensions.height <= 600 && dimensions.width >= 400 && dimensions.width <= 900 ? false : true ,
        value: "HK_XS",
      });
      newOptions.push({
        label: "Omnia/mini y HK-XS con Blumotion",
        disabled: dimensions.height >= 300 && dimensions.height <= 600 && dimensions.width >= 400 && dimensions.width <= 900 ? false : true ,
        value: "HK_XSBLUEMOTION",
      });
      newOptions.push({
        label: "Sin mecanizado",
        disabled: false,
        value: "NO_MECHANIZED",
      });
      /*
      newOptions.push({
        label: "HK-TOP",
        disabled: dimensions.height >= 300 && dimensions.height <= 600 && dimensions.width >= 400 && dimensions.width <= 1200 ? false : true ,
        value: "HK_TOP",
      });
      */
    }

    // newOptions
    setmechanizedOptions([...newOptions]);
    if (
      parseInt(dimensions.height) <= 2400 &&
      totalSize > 2600 &&
      mechanizedSelected &&
      mechanizedSelected.value === "SLIDER_RP50"
    ) {
      changeMechanized(null);
      resetMechanized();
    }
  }, [dimensions, open]);

  //console.log("mechanizedOptions", mechanizedOptions);
  //console.log("m", mechanizedSelected)
  const Option = ({ mechanized }) => {
    //console.log("mechanized", mechanized);
    return (
      <Col className="p-0" xs="12" lg="12" key={`opt-${mechanized.value}`}>
        {/* <Button
          className='mechanized-option'
          variant={
            mechanizedSelected && mechanizedSelected.value === mechanized.value
              ? "primary"
              : "outline-primary"
          }
          onClick={() => changeMechanized(mechanized)}
          disabled={mechanized.disabled}
          size='sm'
        > */}
        <div
          className={`d-flex align-items-center p-1 ${mechanized.disabled && "disabled-mechanized"}`}
          style={{ gap: "15px", cursor: mechanized.disabled ? "default" : "pointer" }}
          onClick={() => {
    if (!mechanized.disabled) {
      changeMechanized(mechanized);
    }
  }}
          disabled={mechanized.disabled}>
          {mechanizedSelected &&
          mechanizedSelected.value === mechanized.value ? (
            <CheckCircleIcon style={{ width: "16px", height: "16px" }} />
          ) : (
            <div style={{ minWidth: "16px", minHeight: "16px" }} />
          )}
          <span
            style={
              mechanizedSelected &&
              mechanizedSelected.value === mechanized.value
                ? { fontWeight: "800" ,fontSize: "16px" }
                : { fontSize: "16px"}
            }>
            {mechanized.label}
          </span>
        </div>
        {/* </Button> */}
      </Col>
    );
  };
  return (
    <Row>
      {mechanizedOptions.map((mechanized, index) => (
        <div key={index}> 
          <Option mechanized={mechanized} />
        </div>
      ))}
    </Row>
  );
};
const mapStateToProps = (state) => ({
  mechanized: state.mechanized,
  dimensions: state.dimensions,
  open: state.open,
  sash: state.sash,
});

const mapDispatchToProps = (dispatch) => ({
  setMechanized(mechanized) {
    dispatch({
      type: "CHANGE_MECHANIZED",
      payload: {
        mechanized,
      },
    });
  },
  resetMechanized() {
    dispatch({
      type: "RESET_MECHANIZED",
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Mechanized);
