import React from "react";
import "./AddHinge.scss";
// import { toast } from 'react-toastify';
import { connect } from "react-redux";
import { makeid } from "../../confirmation/Confirmation";
import { toast } from "react-toastify";
import { hinges } from "./AddHinge";

const inicialdistance = 120;
const distanceBetween = 70;
const inicialVal = 4;
const endVal = 8;
class SelectHinger extends React.Component {
  constructor() {
    super();
    this.state = {
      id: "divHing",
      icoPlus: "fa-add-box",
      selected: false,
      distance: {},
      size: 0,
    };
    this.selectHinger = this.selectHinger.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.checkValue = this.checkValue.bind(this);
    this.hinge = this.hinge.bind(this);
    this.changeHinge = this.changeHinge.bind(this);
    this.calculateValueToInput = this.calculateValueToInput.bind(this);
    this.changeInputEndReverse = this.changeInputEndReverse.bind(this);
    this.suggestion_hinge = this.suggestion_hinge.bind(this);
    this.findMax = this.findMax.bind(this);
    this.findMin = this.findMin.bind(this);
  }

  componentDidMount() {
    let { open, width, height } = this.props.match.params;
    let { openTo, val, addHinge} = this.props;
    let input_distance = document.getElementById(
      `input-distance-${openTo}-${val.value}`
    );
    let size = parseInt(open === "Right" || open === "Left" ? height : width);
    this.setState({ size }); //se toma el size mayor

    if (val.value === inicialVal) {
      let max = size - inicialdistance - distanceBetween;
      let distance = this.hinge(val.value, inicialdistance);
      this.setState({ distance });
      addHinge(distance);
      hinges.push(distance);

      // min and max first input
      input_distance.value = inicialdistance;
      input_distance.min = 0;
      input_distance.max = max;
      this.calculateValueToInput(distance.sizeHinge, 0, max, input_distance);
      input_distance.classList.add("is-valid");
    } else if (val.value === endVal) {
      // change last hinge 120mm
      let input_end = document.getElementById(
        `input-distance-${openTo}-${val.value}-end`
      );
      input_end.value = inicialdistance;
      input_end.min = distanceBetween;
      input_end.max = inicialdistance + distanceBetween;
      // let distance = this.hinge(val.value, inicialdistance)
      // addHinge(distance)

      // hinge lasted calculate
      let hingeLast = size - inicialdistance;
      let min = inicialdistance + distanceBetween;
      let distance = this.hinge(val.value, hingeLast);
      input_distance.value = hingeLast;
      input_distance.max = size;
      input_distance.min = min;
      this.setState({ distance });
      addHinge(distance);
      hinges.push(distance);
      this.calculateValueToInput(distance.sizeHinge, min, size, input_distance);
      input_distance.classList.add("is-valid");
      this.suggestion_hinge(openTo, size);
    }
  }

  suggestion_hinge(opensTo, size) {
   if (size > 1000) {
      if (size <= 1500) {
        document.getElementById(`${opensTo}-6`).click();
      } else if (size <= 2100) {
        document.getElementById(`${opensTo}-5`).click();
        document.getElementById(`${opensTo}-7`).click();
      } else if (size <= 2400) {
        document.getElementById(`${opensTo}-5`).click();
        document.getElementById(`${opensTo}-6`).click();
        document.getElementById(`${opensTo}-7`).click();
      }
   }
  }

  findMax(max) {
    this.props.hinges.map((hinge) => {
      const { val } = this.props;

      if (hinge.order > val.value && hinge.sizeHinge < max) {
        max = hinge.sizeHinge;
      }
    });
    return max;
  }
  findMin(min) {
    const { val } = this.props;
    this.props.hinges.map((hinge) => {
      if (hinge.order < val.value && hinge.sizeHinge > min) {
        min = hinge.sizeHinge;
      }
    });
    return min;
  }
  selectHinger() {
    let { openTo, val, addHinge, removeHinge } = this.props;
    let { open, width, height } = this.props.match.params;
    let size = parseInt(open === "Right" || open === "Left" ? height : width);
    let input_distance = document.getElementById(
      `input-distance-${openTo}-${val.value}`
    );
    if (val.value !== inicialVal && val.value !== endVal) {
      const min_door = 120;
      const max_door = size - 120;
      var min = min_door;
      var max = max_door;
      let distanceRecommended = Math.round(size / 4);
      if (!this.state.selected) {
        max = this.findMax(max);
        min = this.findMin(min);
        if (max === max_door && min === min_door) {
          if (val.section === "B") {
            distanceRecommended = distanceRecommended * 1;
          }
          if (val.section === "C") {
            distanceRecommended = distanceRecommended * 2;
          }
          if (val.section === "D") {
            distanceRecommended = distanceRecommended * 3;
          }
        } else {
          if (max - min >= 140) {
            distanceRecommended = min + Math.round((max - min) / 2);
          } else {
            toast.error("No se puede agregar la bisagra.", { autoClose: 3000 });
            return;
          }
        }
        input_distance.classList.remove("is-valid");
        let newDistance = this.hinge(val.value, distanceRecommended);
        this.setState({
          distance: newDistance,
          selected: !this.state.selected,
          icoPlus: !this.state.selected % 2 ? "fa-minus-box" : "fa-add-box",
        });
        input_distance.value = distanceRecommended;
        this.checkValue(input_distance, newDistance);
        addHinge(newDistance);
      } else {
        removeHinge(this.state.distance);
        this.setState({
          distance: {},
          selected: !this.state.selected,
          icoPlus: !this.state.selected % 2 ? "fa-minus-box" : "fa-add-box",
        });
        input_distance.classList.remove("is-invalid");
        input_distance.classList.add("is-valid");

        input_distance.value = 0;
      }
    }
  }

  calculateValueToInput(sizeHinge, min, max, input_distance) {
    if (sizeHinge < min) {
      if(min < 0) {min = 0}
      return this.changeHinge(input_distance, min);
    } else if (sizeHinge > max) {
      if(max < 0) {max = 0}
      return this.changeHinge(input_distance, max);
    } else {
      return this.changeHinge(input_distance, sizeHinge);
    }
  }

  changeHinge(input_distance, sizeHinge) {
    let { val } = this.props;
    let newDistance = this.hinge(val.value, sizeHinge);
    this.setState({ distance: newDistance });
    input_distance.value = sizeHinge;
    return newDistance;
  }

  changeValue() {
    let { openTo, val, changeValueHinge } = this.props;
    let input_distance = document.getElementById(
      `input-distance-${openTo}-${val.value}`
    );
    let size = parseInt(input_distance.value);
    size = isNaN(size) ? 0 : size;
    let distance = this.hinge(val.value, size);
    console.log("distance", hinges);
    changeValueHinge(this.state.distance, distance);
    this.setState({ distance });
    this.checkValue(input_distance, distance);
  }

  hinge(order, sizeHinge) {
    return {
      order: parseInt(order),
      sizeHinge,
      id: makeid(5),
    };
  }

  checkValue(element, distance) {
    // let submitButton = document.querySelector('.btn-next')
    let arrayMin = [];
    let arrayMax = [];
    let allHinges = this.props.hinges.length === 0 ? hinges : this.props.hinges;
    let { open, width, height } = this.props.match.params;
    let size = parseInt(open === "Right" || open === "Left" ? height : width);
    allHinges.forEach((value) => {
      if (distance.order > value.order) {
        let distanceRadioMin = value.sizeHinge + distanceBetween;
        arrayMin.push(distanceRadioMin);
      } else if (distance.order < value.order) {
        let distanceRadioMax = value.sizeHinge - distanceBetween;
        arrayMax.push(distanceRadioMax);
      }
    });
    arrayMax = arrayMax.length === 0 ? [this.state.size] : arrayMax;
    arrayMin = arrayMin.length === 0 ? [0] : arrayMin;
    const max = Math.min(...arrayMax);
    const min = Math.max(...arrayMin);
    element.min = min;
    element.max = max;
    const borderMin = 90;
    const borderMax = size - 90;

    if(distance.sizeHinge < borderMin || distance.sizeHinge > borderMax){
      element.classList.remove("is-valid");
      element.classList.add("is-invalid");
      toast.error("Posición inválida", { autoClose: 3000 });
      return false;

    }else if (min <= distance.sizeHinge && max >= distance.sizeHinge) {
      element.classList.add("is-valid");
      element.classList.remove("is-invalid");
      return true;
    }else {
      element.classList.remove("is-valid");
      element.classList.add("is-invalid");
      toast.error("Posición inválida", { autoClose: 3000 });
      return false;
    }
  }

  /* 
  Javiera hola
Buenas tardes, respecto a la ubicación de las bisagras nosotros trabajamos como estandar sugerido: distancia de 120mm desde el borde de la puerta al eje instalación de la bisagra. (esto se aplica a las bisagras de los extremos).
Las de ubicación intermedia se pueden distribuir proporcionalmente, sin embargo la medida mínima que debe haber entre una y otra es de 70mm.
Ahora existen las siguientes sugerencias respecto a la cantidad de bisagras:
Puerta de hasta 1000mm de altura          2 bisagras
Puerta de 1001 a 1500mm de altura        3 bisagras
Puerta de 1501 a 2000mm de altura        4 bisagras
Puerta de 2001 a 2150mm de altura        5 bisagras
respecto al tipo de bisagra que se usa tenemos:
Perfil ancho: STYLO-OFF (todos los SKU de bisagras)
Perfil angosto: OMNIA (bisagra omnia cierre suave/ bisagra omnia sin cierre suave).
  
  */
  changeInputEndReverse() {
    let { openTo, val } = this.props
    let { size } = this.state
    let inputReverse = document.getElementById(
      `input-distance-${openTo}-${val.value}-end`
    )
    let input_distance = document.getElementById(
      `input-distance-${openTo}-${val.value}`
    )
    let calculateDiference = parseInt(inputReverse.value) - inicialdistance
    let hingeLast = size - inicialdistance
    input_distance.value = hingeLast - calculateDiference
    this.changeValue()
  }

  render() {
    let { openTo, side, val , mechanized} = this.props;
    let inicialLine = openTo === "Right" || openTo === "Left" ? "Top" : "Left";
    let endLine = openTo === "Right" || openTo === "Left" ? "Bottom" : "Right";
    let positionLine =
      openTo === "Bottom" || openTo === "Top" ? "horizontal" : "vertical";
    let plusPosition = openTo === "Left" || openTo === "Top" ? 2 : 0;
    let plusRightBottom = openTo === "Right" || openTo === "Bottom" ? 2 : 0;

    // position of size
    let size = "";
    if (val.value === inicialVal || val.value === endVal) {
      if (openTo === "Top" || openTo === "Bottom") {
        size = openTo === "Top" ? "-end" : "";
      } else {
        size = val.value === endVal ? `-end` : `-start`;
      }
    }
    return (
      <>
        <div
          className={`select-hinge hinge-${openTo}`}
          style={{
            gridArea:
              openTo === "Left" || openTo === "Right"
                ? `${val.value} / ${side}`
                : `${side} / ${val.value}`,
            display: mechanized?.label === "Sin mecanizado" ? "none" : "grid",  
          }}
        >
          <div className='add-conatiner'>
            <span
              id={`${openTo}-${val.value}`}
              className={`fa ${
                val.value !== inicialVal && val.value !== endVal
                  ? `${this.state.icoPlus} add-cursor-pointer`
                  : "fa-minus-box"
              }`}
              style={{ fontSize: "1.9rem" }}
              onClick={this.selectHinger}
            />
          </div>
          <div className='arrow-conatiner'>
            <i className={`fa fa-arrow-${openTo.toLowerCase()}-mdc`}></i>
          </div>
          <div className='text-distance'>
            <input
              id={`input-distance-${openTo}-${val.value}`}
              className='text-black text-center form-control p-1 input-size-position'
              hidden={
                this.state.distance.id === undefined || val.value === endVal
              }
              disabled={this.state.distance.id === undefined}
              type='number'
              onBlur={this.changeValue}
              required
            />
            {val.value === endVal ? (
              <input
                id={`input-distance-${openTo}-${val.value}-end`}
                className='text-black text-center form-control p-1 input-size-position'
                type='number'
                onBlur={this.changeInputEndReverse}
                required
              />
            ) : null}
          </div>
          <div className='section-data'>
            <p className='text-black m-0'>{val.section}</p>
          </div>
        </div>
        <div
          className={`d-flex justify-content-center align-items-center ${
            val.value === inicialVal
              ? `border-box-${inicialLine}`
              : val.value === endVal
              ? `border-box-${endLine}`
              : null
          }`}
          style={{
            gridArea:
              openTo === "Left" || openTo === "Right"
                ? `${val.value} / ${side - plusPosition + 1}`
                : `${side - plusPosition + 1} / ${val.value}`,
          }}
        >
          <div className={`arrow-see-${positionLine}`}></div>
        </div>
        {val.value === inicialVal || val.value === endVal ? (
          <div
            className={`d-flex align-items${size} justify-content-center`}
            style={{
              gridArea:
                openTo === "Left" || openTo === "Right"
                  ? `${val.value} / ${side - plusPosition + plusRightBottom}`
                  : `${side - plusPosition + plusRightBottom} / ${val.value}`,
            }}
          >
            <small className='text-black font-weight-bold'>
              <small>
                {val.value === inicialVal ? `0 mm` : `${this.state.size} mm`}
              </small>
            </small>
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  hinges: state.hinges.hinge_position,
  mechanized: state.mechanized,
});

const mapDispatchToProps = (dispach) => ({
  addHinge(hinge) {
    dispach({
      type: "ADD_HINGE",
      hinge,
    });
  },
  removeHinge(hinge) {
    dispach({
      type: "DELETE_HINGE",
      hinge,
    });
  },
  changeValueHinge(oldHinge, hinge) {
    dispach({
      type: "CHANGE_VALUE_HINGE",
      oldHinge,
      hinge,
    });
  },
});
const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(SelectHinger);

export default component;
