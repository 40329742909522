import React, { useState } from "react";
import { Col, Container, Row, Modal, Button } from "react-bootstrap";
import "./Index.css";
import NavBar from "../components/navBar/NavBar";
import Open from "./open/Open";
import Preview from "./preview/Preview";
import Selected from "./selected/Selected";
// import Questions from "./questions/Questions";
// import Profile from "./profile/Profile";
// import Hinge from "./hinge/Hinge";
// import Repeat from "./repeat/Repeat";
// import Confimation from "./confirmation/Confirmation";
import Cart from "./cart/Cart";
import Finished from "./finished/Finished";
import { Route, useLocation } from "react-router-dom";
// import QuestionUncover from "./hinge/QuestionUncover";
import { connect } from "react-redux";
import Medidas from "./medidas";
import Mecanizado from "./mecanizado";
import Resumen from './resumen';

function MyVerticallyCenteredModal({ show, onHide, changeShowView }) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          Todo el progreso
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Selected changeShowView={changeShowView} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  );
}

const Index = ({ ...props }) => {
  const [show, setshow] = useState("Open");
  const [hinges, sethinges] = useState([]);
  const [modalShow, setmodalShow] = useState(false);
  const location = useLocation();
  const changeModalView = () => {
    setmodalShow(!modalShow);
  };

  const changeShowView = (viewShow) => {
    setshow(viewShow);
  };

  const isHingesStep = () => {
    const locationArray = location.pathname.split("/");
    return (
      locationArray.includes("profile") && !locationArray.includes("hinge")
    );
  };

  const isOnRepeat = () => {
    const locationArray = location.pathname.split("/");
    return locationArray.includes("repeat");
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        margin: '0px',
        width: '100%',
        maxWidth: '100%',
        padding: '0px',
      }}
    >
      <Row>
        <Route
          exact
          path='/client/type/:type/open'
          component={(props) => (
            <NavBar {...props} stepNumber={1} showCart={true} redirectTo={"/"} />
          )}
        />
        <Route
          exact
          path='/client/type/:type/open/:open'
          component={(props) => (
            <NavBar {...props} stepNumber={2} showCart={true} redirectTo={"/"} />
          )}
        />
        <Route              
          exact
          path='/client/type/:type/open/:open/width/:width/height/:height/line/:line/line_sku/:line_sku/nameColor/:nameColor/blackFilm/:bfilm/profile/:profile/'
          component={(props) => (
            <NavBar {...props} stepNumber={3} showCart={true} redirectTo={"/"} />
          )}
        />
        <Route
          exact
          path='/client/type/:type/open/:open/width/:width/height/:height/line/:line/line_sku/:line_sku/nameColor/:nameColor/blackFilm/:blackFilm/profile/:profile/hinge/:hinge/mechanized/:mechanized/repeat/:repeat'
          component={(props) => (
            <NavBar {...props} stepNumber={4} showCart={true} redirectTo={"/"} />
          )}
        />
        <Route
          path='/client/cart'
          component={(props) => (
            <NavBar {...props} stepNumber={5} showCart={true} redirectTo={"/"} />
          )}
        />
        <Route
          path='/client/finished'
          component={(props) => (
            <NavBar {...props} showCart={false} redirectTo={"/"} />
          )}
        />
        <Route path='/client/cart' component={(props) => <Cart {...props} />} />
        <Route path='/client/finished' component={Finished} />
      </Row>
      <Route path='/client/type/:type/open'>
        {/* {!isOnRepeat() && (
          <Row>
            <Col xs='12' className='px-0 d-none d-sm-block'>
              <Selected changeShowView={changeShowView} />
            </Col>
          </Row>
        )} */}

        <div
          className='d-block d-sm-none float d-flex align-items-center justify-content-center'
          onClick={changeModalView}
        >
          <Row>
            <Col xs='12' className='text-center'>
              <i className='fa fa-eye-open'></i>
            </Col>
            <Col xs='12' className='text-center'>
              <small>
                <small>Progreso</small>
              </small>
            </Col>
          </Row>
        </div>
        <Row className='body-container'>
          <Col
            id='components-container'
            className='px-0 button-priority h-100'
            xs='12'
          >
            <Route
              exact
              path='/client/type/:type/open'
              component={(props) => <Open {...props} />}
            />
            <Route
              exact
              path='/client/type/:type/open/:open'
              component={(props) => <Medidas {...props} />}
            />
            <Route
              exact
              path='/client/type/:type/open/:open/width/:width/height/:height/line/:line/line_sku/:line_sku/nameColor/:nameColor/blackFilm/:blackFilm/profile/:profile'
              component={(props) => <Mecanizado {...props} />}
            />
            <Route
              exact
              path='/client/type/:type/open/:open/width/:width/height/:height/line/:line/line_sku/:line_sku/nameColor/:nameColor/blackFilm/:blackFilm/profile/:profile/hinge/:hinge/mechanized/:mechanized/repeat/:repeat'
              component={(props) => <Resumen {...props} />}
            />
            {/* <Route
              exact
              path='/client/type/:type/open/:open/width/:width/height/:height/mechanized/:mechanized/line/:line/line_sku/:line_sku/nameColor/:nameColor/blackFilm/:blackFilm/profile/:profile'
              component={(props) => (
                <QuestionUncover
                  {...props}
                  preview={() => (
                    <PreviewContainer className='mt-5' {...props} />
                  )}
                />
              )}
            /> */}
            {/* <Route
              exact
              path='/client/type/:type/open/:open/width/:width/height/:height/mechanized/:mechanized/line/:line/line_sku/:line_sku/nameColor/:nameColor/blackFilm/:blackFilm/profile/:profile/hinge/:hinges'
              component={(props) => <Repeat {...props} />}
            />
            <Route
              exact
              path='/client/type/:type/open/:open/width/:width/height/:height/mechanized/:mechanized/line/:line/line_sku/:line_sku/nameColor/:nameColor/blackFilm/:blackFilm/profile/:profile/hinge/:hinges/repeat/:repeat'
              component={(props) => (
                <Confimation {...props} changeShowView={changeShowView} />
              )}
            /> */}
          </Col>
        </Row>
      </Route>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={changeModalView}
        changeShowView={changeShowView}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
