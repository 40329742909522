import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import "./index.css";
import { formatPriceDots } from "../../../helpers/formatPrice";
import { getMechanized } from "../../size/mechanized/options";
// fotoAmbientada
const ItemDoors = ({ ...props }) => {
  const deleteProduct = ({ id, publicPriceTotal }) => {
    let { deleteProductCar, deleteProductList } = props;
    deleteProductCar({ id, publicPriceTotal });
    deleteProductList(id);
  };
  const history = useHistory();

  const [showPrices, setshowPrices] = useState(false);
  const [prices, setprices] = useState({
    publicPrice: null,
    publicPriceTotal: null,
  });
  const getPrice = () => {
    const publicPrice = props.array_price.find((price) => {
      return price.door_id === props.id;
    });
    return publicPrice;
  };

  const renderMechanized = (mechanized) => {
    const mechanizedValue =
      typeof mechanized == "string" ? mechanized : mechanized.value;
    const option = getMechanized(mechanizedValue);
    if (option) {
      if (option.value.includes("HINGE"))
        return `Destaje para ${option.label.toLowerCase()}`;
      return option.label;
    }
    return "";
  };

  const renderHinges = (hinges) => {
    if (hinges.split("x").length > 1) {
      let texthinges = "";
      hinges
        .split("x")
        .forEach((val, key) =>
          key === 0
            ? (texthinges = val)
            : (texthinges = `${texthinges} x ${val}`)
        );
      return texthinges;
    }
    if (props.side === "Avento") {
      let glossArr = props.sash_attributes?.gloss?.split(" ");
      let sash = "";
      if (glossArr?.length > 0) {
        sash = glossArr[1];
      }
      if (sash === "STYLO" || sash === "OFF")
        return "JUEGO BISAGRAS AVENTOS HF MADERA";

      if (sash === "OMNIA") return "JUEGO BISAGRAS AVENTOS ALUMINIO 19MM";
    } else if (mechanized.value === "SLIDER_RP50") {
      return "Destaje sistema RP50";
    }
    return "Sin destajes";
  };

  function reloadPage() {
    window.location.reload();
  }

  useEffect(() => {
    const newPrices = getPrice();
    if (!!newPrices && newPrices.publicPrice !== 1) {
      setprices(newPrices);
      setshowPrices(true);
    } else {
      setshowPrices(false);
    }
  }, [props.array_price]);

  const {
    id,
    line,
    nameColor,
    base,
    height,
    hinges,
    side,
    profile,
    repetition,
    type,
    filling_sku,
    sash_sku,
    line_sku,
    urlColor,
    filling_attributes,
    sash_attributes,
    mechanized,
    black_film,
  } = props;
  console.log("ItemDoors ===>", props);
  let sash_gloss = sash_attributes?.gloss?.split(" ");
  let filling_gloss = filling_attributes?.gloss?.split(" ");
  let hinges_value = hinges == "" ? "noHinges" : hinges;
  let texthinges = renderHinges(hinges);

  return (
    <div
      className="list-group-item list-group-item-action"
      style={{ padding: 0 }}
    >
      {/* <Row>
        <Col className='text-left col-12'>
          <p>{`${line}`}</p>
        </Col>
      </Row> */}
      <Row>
        <Col className="col-12 col-lg-3">
          <div
            className="color-content"
            style={{
              backgroundImage: `url(${urlColor})`,
              backgroundSize: "contain",
            }}
          />
        </Col>
        <Col className="col-12 col-lg-9">
          <ul className="list-group list-group-flush text-left">
            {sash_gloss ? (
              <li className="list-group-item p-0">
                <span className="text-bold">{`Nombre perfil: `}</span>
                <small className="font-weight-bold">
                  {`${sash_gloss?.[0]} ${sash_gloss?.[1]} ${sash_gloss?.[2]} ${sash_gloss?.[3]}`}
                </small>
              </li>
            ) : (
              ""
            )}

            <li className="list-group-item p-0">
              <span className="text-bold">Tipo de puerta:</span>
              <br />
              <small className="font-weight-bold">{`${line}`}</small>
            </li>
            <li className="list-group-item p-0">
              <span className="text-bold">{`Cantidad: `}</span>
              <small className="font-weight-bold">{`${repetition}`}</small>
            </li>
            <li className="list-group-item p-0">
              <span className="text-bold">
                Tipo de mecanizado:
              </span>
              <br />
              <small className="font-weight-bold">{`${renderMechanized(
                mechanized
              )}`}</small>
            </li>
            <li className="list-group-item p-0">
              <span className="text-bold">{`Cantidad: `}</span>
              <small className="font-weight-bold">{`${repetition}`}</small>
            </li>
            <li className="list-group-item p-0">
              <span className="text-bold">{`Relleno: `}</span>
              <br />
              <small className="font-weight-bold">
                {`${filling_gloss[0]} ${filling_gloss[1]} ${filling_gloss[2]} ${filling_gloss[3]}`}
              </small>
              <br />
              <span className="text-bold">{`Cantidad: `}</span>
              <small className="font-weight-bold">{`${repetition}`}</small>
            </li>
            <li className="list-group-item p-0 font-weight-bold">
              <span className="text-bold">{`Posición de bisagras:`}</span>
              <br />
              <small className="font-weight-bold">
                {hinges.split("x").length > 1 ? `${texthinges} mm` : texthinges}
              </small>
            </li>
            <li className="list-group-item p-0">
              <span className="text-bold">
                Codigo de producto:
              </span>
              <br />
              <small className="font-weight-bold">{line_sku}</small>
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="bottom-line">
        <Col className="col-lg-6 col-12 order-2 order-lg-1">
          <Row>
            <Col className="col-12 col-lg-6">
              <div
                // variant='outline-primary'
                className="product-option-button"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  deleteProduct({
                    id,
                    publicPriceTotal: prices.publicPriceTotal,
                  });
                  window.location.reload();
                }}
              >
                <small> Eliminar </small>
                <img src="/trash.svg" width={25} height={25} />
              </div>
            </Col>
            <Col className="col-12 col-lg-6">
              <div
                className="product-option-button "
                style={{
                  cursor: "pointer",
                  fontSize: ".8rem",
                }}
                onClick={() => {
                  deleteProduct({
                    id,
                    publicPriceTotal: prices.publicPriceTotal,
                  });
                  history.push(
                    `/client/type/normal/open/${side}/width/${base}/height/${height}/line/${line}/line_sku/${line_sku}/nameColor/${filling_sku}/blackFilm/${black_film}/profile/${sash_sku}/hinge/${hinges_value}/mechanized/${mechanized}/repeat/${repetition}`
                  );
                }}
              >
                <small> Editar </small>
                <img src="/edit.png" width={25} height={25} />
              </div>
            </Col>
          </Row>
        </Col>
        <Col className="col-12 col-lg-6 order-1 order-lg-2">
          {showPrices && (
            <Col xs="12">
              <Row>
                <Col md="6" xs="6" className="text-right "></Col>
                <Col
                  md="6"
                  xs="6"
                  className="text-right font-weight-bold d-flex justify-content-end"
                >
                  <table>
                    <tbody className="text-right text-primary">
                      <tr>
                        <td className="pr-2 small" style={{ fontSize: "16px" }}>
                          Unidad:
                        </td>
                        <td style={{ fontSize: "16px" }}>
                          ${formatPriceDots(prices.publicPrice)}
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="pr-2 small"
                          style={{
                            fontSize: "16px",
                            padding: "1rem 0rem !important",
                          }}
                        >
                          Total:
                        </td>
                        <td style={{ fontSize: "16px" }}>
                          ${formatPriceDots(prices.publicPriceTotal)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Col>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  personalData: state.statePersonal.personalData,
  array_price: state.cart.array_price,
});

const mapDispatchToProps = (dispach) => ({
  deleteProductCar({ id, publicPriceTotal }) {
    dispach({
      type: "DELETE_PRODUCT",
      id,
      price: publicPriceTotal,
    });
  },
  deleteProductList(id) {
    dispach({
      type: "DELETE_LIST_DOORS",
      payload: { id },
    });
  },
});

const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(ItemDoors);

export default component;
