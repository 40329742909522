import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Route, Link } from "react-router-dom";
import DesignSizeContainer from "./DesignSizeContainer";
import DesignFellingContainer from "./DesignFellingContainer";
import DoorToOpen from "../../components/doorToOpen/DoorToOpen";
import doorOpened from "../../img/doorOpened.png";
import BorderSelected from "./BorderSelected";
import "./Selected.scss";
import { getMaterial } from "../../api/APIGet";

const DesingOpen = ({ ...props }) => {
  let { open, type } = props.match.params;
  return (
    <Link to={`/client/type/${type}`}>
      <DoorToOpen id={open} onlyPreview={true} imgSrc={doorOpened} />
    </Link>
  );
};

const HingeSelected = (props) => {
  const {
    open,
    type,
    width,
    height,
    line,
    line_sku,
    nameColor,
    profile,
    hinge,
    mechanized,
  } = props.match.params;

  const [hingeText, setHingeText] = useState("Sin destajes");

  useEffect(() => {
    formatHingesText();
  }, []);

  const formatHingesText = async () => {
    if (hinge.split("x").length > 1) {
      let hingeText = "";
      hinge
        .split("x")
        .forEach((val, key) =>
          key === 0 ? (hingeText = val) : (hingeText = `${hingeText} x ${val}`)
        );
      return setHingeText(hingeText);
    }
    if (open === "Avento") {
      const sashProd = await getMaterial(profile).then((sash) => {
        return sash;
      });
      let glossArr = sashProd.attributes.gloss.split(" ");
      let sash = "";
      if (glossArr.length > 0) {
        sash = glossArr[1];
      }
      if (sash === "STYLO" || sash === "OFF")
        return setHingeText("Juego bisagras aventos hf madera");

      if (sash === "OMNIA")
        return setHingeText("Juego bisagras aventos aluminio 19mm");
    } else if (mechanized === 'SLIDER_RP50'){
      return setHingeText("Destaje Sistema RP50")
    }
    return setHingeText("Sin destajes");
  };

  const RenderHinges = () => {
    if (hinge.split("x").length > 1) {
      return (
        <div id='step-text-box'>
          <span>{`${hinge.split("x").length} Destajes bisagras`}</span>
          <p className='m-0 hinge-text'>
            {hingeText}
            <small> mm</small>
          </p>
        </div>
      );
    } else {
      return (
        <div id='step-text-box'>
          <p className='m-0 hinge-text'>{hingeText}</p>{" "}
        </div>
      );
    }
  };

  return (
    <Link
      to={`/client/type/${type}/open/${open}/width/${width}/height/${height}/mechanized/${mechanized}/line/${line}/line_sku/${line_sku}/nameColor/${nameColor}/profile/${profile}/`}
    >
      <RenderHinges />
    </Link>
  );
};

const RepeatSelected = ({ match, ...props }) => {
  const {
    open,
    type,
    width,
    height,
    line,
    line_sku,
    nameColor,
    profile,
    hinge,
    mechanized,
    blackFilm
  } = match.params;
  return (
    <Link
      to={`/client/type/${type}/open/${open}/width/${width}/height/${height}/mechanized/${mechanized}/line/${line}/line_sku/${line_sku}/nameColor/${nameColor}/blackFilm/${blackFilm}/profile/${profile}/hinge/${hinge}/`}
    >
      <div id='step-text-box'>
        <span style={{ width: "100%" }}>Unidades</span>
        <h3>{match.params.repeat}</h3>
      </div>
    </Link>
  );
};

const Selected = ({ summary, ...props }) => {
  const numberStyles = summary ? "step-number-rounded" : "step-number";
  const lgColSize = summary ? "4" : "";
  const stepBoxStyle = summary ? "step-box" : "step-box-breadcumbs";

  const renderStepNumber = (number) => <p className={numberStyles}>{number}</p>;
  return (
    <Container className='selected-box'>
      <Col lg={lgColSize} style={{ display: "flex" }}>
        <div className={`${stepBoxStyle}`}>
          <Route
            path='/client/type/:type/open/:open'
            component={(props) => (
              <>
                {renderStepNumber(1)}
                <div
                  style={
                    summary
                      ? {
                          height: "88px",
                          width: "88px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }
                      : { width: "80px", height: "80px" }
                  }
                >
                  <DesingOpen {...props} />
                </div>
              </>
            )}
          />
        </div>
      </Col>
      <Col lg={lgColSize} style={{ display: "flex" }}>
        <div className={stepBoxStyle}>
          <Route
            path='/client/type/:type/open/:open/width/:width/height/:height'
            component={(props) => (
              <>
                {renderStepNumber(2)}
                <DesignSizeContainer
                  {...props}
                  changeSizeToPreview={props.changeSizeToPreview}
                />
              </>
            )}
          />
        </div>
      </Col>
      <Col lg={lgColSize} style={{ display: "flex" }}>
        <div className={stepBoxStyle}>
          <Route
            path='/client/type/:type/open/:open/width/:width/height/:height/mechanized/:mechanized/line/:line/line_sku/:line_sku/nameColor/:nameColor/blackFilm/:blackFilm'
            component={(props) => (
              <>
                {renderStepNumber(3)}
                <DesignFellingContainer
                  {...props}
                  fillingColorSelected={props.fillingColorSelected}
                />
              </>
            )}
          />
        </div>
      </Col>
      <Col lg={lgColSize} style={{ display: "flex" }}>
        <div className={stepBoxStyle}>
          <Route
            path='/client/type/:type/open/:open/width/:width/height/:height/mechanized/:mechanized/line/:line/line_sku/:line_sku/nameColor/:nameColor/blackFilm/:blackFilm/profile/:profile'
            component={(props) => (
              <>
                {renderStepNumber(4)}
                <BorderSelected {...props} />
              </>
            )}
          />
        </div>
      </Col>
      <Col lg={lgColSize} style={{ display: "flex" }}>
        <div className={stepBoxStyle}>
          <Route
            path='/client/type/:type/open/:open/width/:width/height/:height/mechanized/:mechanized/line/:line/line_sku/:line_sku/nameColor/:nameColor/blackFilm/:blackFilm/profile/:profile/hinge/:hinge/'
            component={(props) => (
              <>
                {renderStepNumber(5)}
                <HingeSelected {...props} />
              </>
            )}
          />
        </div>
      </Col>
      <Col lg={lgColSize} style={{ display: "flex" }}>
        <div className={stepBoxStyle}>
          <Route
            path='/client/type/:type/open/:open/width/:width/height/:height/mechanized/:mechanized/line/:line/line_sku/:line_sku/nameColor/:nameColor/blackFilm/:blackFilm/profile/:profile/hinge/:hinge/repeat/:repeat/'
            component={(props) => (
              <>
                {renderStepNumber(6)}
                <RepeatSelected
                  {...props}
                  profileSelected={props.profileSelected}
                />
              </>
            )}
          />
        </div>
      </Col>
    </Container>
  );
};
export default Selected;
