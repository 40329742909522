import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/sideBar/SideBar';
import { Row, Accordion } from 'react-bootstrap';
import './index.css'

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Size from '../size/Size';
import Filling from '../filling/Filling';
import Profile from '../profile/Profile';
import { connect } from 'react-redux';
import Refill from '../refill';

const Medidas = ({
  history,
  open,
  dimensions,
  filling,
  sash,
  ...props
}) => {
  const openTypes = {
    left: 'izquierda',
    right: 'derecha',
    bottom: 'inferior',
    top: 'superior',
    avento: 'plegable',
    slider: 'corredera',
  };
  const [currentActive, setCurrentActive] = useState(['0']);
  const [colorSelected, setColorSelected] = useState(false);
  const [activeSku, setActiveSku] = useState('');
  const [globalProfile, setGlobalProfile] = useState('');
  const [Relleno, setRelleno] = useState('');
  const [medidasImage, setMedidasImage] = useState('/medidas_bg.png');
  const [selectedLineSku, setSelectedLineSku] = useState('');
  const [selectedLine, setSelectedLine] = useState('');
  const [fillerSku, setFillerSku] = useState('');
  const [selctedProfileSku, setSelectedProfileSku] = useState('');


  const nextValidation = () => {
    const { width, height } = dimensions;
    if (!width || !height) {
      return true;
    }
    if (fillerSku?.trim() === '') {
      return true;
    }
    if (
      fillerSku?.trim() === 'RARAUES4M36025000HBT' &&
      filling.film === ''
    ) {
      return true;
    }
    if (selctedProfileSku === '') {
      return true;
    }
    return false;
  };

  const handleAccordionClick = (targetId) => {
    var temp = currentActive;
    if (temp.includes(targetId)) {
      var index = temp.indexOf(targetId);
      //console.log(index)
      if (temp.length === 1) {
        temp = [];
      } else {
        for (var i = index; i < temp.length - 1; i++) {
          temp[i] = temp[i + 1];
        }
        temp.length = temp.length - 1;
      }
    } else {
      temp.push(targetId);
    }
    setCurrentActive([...temp]);
  };

  const imgs = {
    left: "/images/default_izquierda.png",
    right: "/images/default_derecha.png",
    bottom: "/images/default_inferior.png",
    top: "/images/default_superior.png",
    avento: "/images/default_plegable.png",
    slider: "/images/default_corredera.png",
  };

  useEffect(() => {
    setMedidasImage(imgs[open?.toLowerCase()]);
    if (openTypes[open?.toLowerCase()] && Relleno && globalProfile && globalProfile.toUpperCase().includes(activeSku.toUpperCase())) {
      setMedidasImage(
        `/images/${globalProfile}-${
          Relleno?.[0]?.toUpperCase() + Relleno?.slice(1)
        } ${openTypes[open?.toLowerCase()]}.png`
      );
    } else {
      if(openTypes[open?.toLowerCase()] && colorSelected && !globalProfile){
        setMedidasImage(
          `/images/BASE ${activeSku.toUpperCase()}-${openTypes[open?.toLowerCase()]}.png`
        );
      } else {
        if(openTypes[open?.toLowerCase()] && globalProfile){
          setMedidasImage(
            `/images/PERFIL SOLO_${globalProfile} ${openTypes[open?.toLowerCase()]}.png`
          );
        }
      }
    }
  }, [Relleno, activeSku, globalProfile]);
  console.log(
    'medidas',
    `${globalProfile}-${
      Relleno?.[0]?.toUpperCase() + Relleno?.slice(1)
    } ${openTypes[open?.toLowerCase()]} |color: ${activeSku}`
  );
  console.log(
    'medidas2',
    `/images/PERFIL SOLO_${globalProfile} ${openTypes[open?.toLowerCase()]}.png`,
    `${openTypes[open?.toLowerCase()]}`
  );

  return (
    <Row className="h-100" style={{ backgroundColor: '#F1F1F1' }}>
      <Sidebar
        onNext={() => {
          let ori_path = window.location.pathname;
          if (ori_path[ori_path.length - 1] === '/') {
            ori_path = ori_path.slice(0, ori_path.length - 2);
          }
          let path = `${ori_path}/width/${dimensions.width}/height/${dimensions.height}/`;
          if (fillerSku !== 'RARAUES4M36025000HBT') {
            path =
              path +
              `line/${selectedLine}/line_sku/${selectedLineSku}/nameColor/${fillerSku}/blackFilm/${false}/`;
          } else {
            path =
              path +
              `line/${selectedLine}/line_sku/${selectedLineSku}/nameColor/${fillerSku}/blackFilm/${filling.film}/`;
          }
          path = path + `profile/${selctedProfileSku}/`;
          history.push(path);
        }}
        onBack={() => {
          history.push(`/`);
        }}
        disabledNext={nextValidation()}
        width="col-3"
      >
        <Accordion
          className="d-flex flex-column align-items-center"
          style={{ width: '90%' }}
          activeKey={currentActive}
          defaultActiveKey={['0']}
          alwaysOpen
        >
          <Accordion.Item className="w-100" eventKey="0">
            <Accordion.Header
              className="d-flex justify-content-between align-items-center mt-4 mt-lg-5 w-100"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleAccordionClick('0');
              }}
            >
              <span
                className="font-weight-bold"
                style={{ color: 'black' }}
              >
                1. INGRESO DE MEDIDAS{' '}
                <span style={{ color: 'red' }}> * </span>
              </span>
            </Accordion.Header>
            <Accordion.Body className="w-100">
              <Size {...props} />
            </Accordion.Body>
          </Accordion.Item>
          <div className="divider-vert" />
          <Accordion.Item className="w-100" eventKey="3">
            <Accordion.Header
              className="d-flex justify-content-between align-items-center w-100"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleAccordionClick('3');
              }}
            >
              <span
                className="font-weight-bold"
                style={{ color: 'black' }}
              >
                2. COLOR DE PERFIL{' '}
                <span style={{ color: 'red' }}> * </span>
              </span>
            </Accordion.Header>
            <Accordion.Body className="w-100">
              <Refill
                {...props}
                setColorSelected={setColorSelected}
                setActiveSku={setActiveSku}
                setGlobalProfile={setGlobalProfile}
              />
            </Accordion.Body>
          </Accordion.Item>
          {colorSelected && (
            <>
              <div className="divider-vert" />
              <Accordion.Item className="w-100" eventKey="2">
                <Accordion.Header
                  className="d-flex justify-content-between align-items-center w-100"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleAccordionClick('2');
                  }}
                >
                  <span
                    className="font-weight-bold"
                    style={{ color: 'black' }}
                  >
                    3. TIPO DE PERFIL{' '}
                    <span style={{ color: 'red' }}> * </span>
                  </span>
                </Accordion.Header>
                <Accordion.Body className="w-100">
                  <Profile
                    {...props}
                    activeSku={activeSku}
                    setGlobalProfile={setGlobalProfile}
                    setRelleno={setRelleno}
                    globalProfile={globalProfile}
                    setSelectedProfileSku={setSelectedProfileSku}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </>
          )}
          {globalProfile && (
            <>
              <div className="divider-vert" />
              <Accordion.Item className="w-100" eventKey="1">
                <Accordion.Header
                  className="d-flex justify-content-between align-items-center w-100"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleAccordionClick('1');
                  }}
                >
                  <span
                    className="font-weight-bold"
                    style={{ color: 'black' }}
                  >
                    {' '}
                    4. SELECCIONE RELLENO{' '}
                    <span style={{ color: 'red' }}> * </span>
                  </span>
                </Accordion.Header>
                <Accordion.Body className="w-100">
                  <Filling
                    {...props}
                    globalProfile={globalProfile}
                    setRelleno={setRelleno}
                    Relleno={Relleno}
                    setSelectedLine={setSelectedLine}
                    setSelectedLineSku={setSelectedLineSku}
                    setFillerSku={setFillerSku}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </>
          )}
        </Accordion>
      </Sidebar>
      <div
        className="col-9 p-0 m-0 d-flex justify-content-center align-items-center"
        style={{ height: '100%', position: 'relative' }}
      >
        <img src={medidasImage} style={{ height: '80%' }} />
      </div>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  open: state.open,
  dimensions: state.dimensions,
  filling: state.filling,
  sash: state.sash,
});

const wrapper = connect(mapStateToProps);
const component = wrapper(Medidas);

export default component;
