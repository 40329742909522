const defaultState = {
  height_furniture:''
}

function reducer(state = defaultState, action) {
  switch (action.type) {
    case "HEIGHT_FURNITURE": {
      return {
        ...state,
        height_furniture: action.height_furniture
      };
    }
    default:
      return state;
  }
}

export default reducer;