const defaultState = {
  height: 0,
  width: 0,
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "CHANGE_DIMENSIONS": {
      return {
        ...state,
        width: payload ? payload.width : defaultState.width,
        height: payload ? payload.height : defaultState.height,
      };
    }
    default:
      return state;
  }
}

export default reducer;
