import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import InputTextField from "../../../components/InputTextField";
import Select from "../../../components/select";
import { allCommunes, filteredCommunes } from "../../../api/APIGet";
import { connect } from "react-redux";
import "./NewAddress.css"

const NewAddress = ({
  changeShippingAddress,
  resetShippingAddress,
  ...props
}) => {
  const [email, setemail] = useState("");
  const [commune, setcommune] = useState("");
  const [phone, setphone] = useState("");
  const [street_addr, setstreet_addr] = useState("");
  const [communes, setcommunes] = useState([]);

  const changeValueBilling = (value, valueName) => {
    changeShippingAddress({ [valueName]: value });
  };

  const changeCommune = (value) => {
    setcommune(value);
    const communeObj = communes.find((commune_to_find) => {
      return value === commune_to_find.value;
    });
    changeValueBilling(communeObj.value, "commune_id");
    changeValueBilling(communeObj.label, "commune");
  };

  useEffect(() => {
    resetShippingAddress();
    changeShippingAddress({ kind: "new" });
    allCommunes()
      .then((communes) => {
        setcommunes(
          communes.map((commune) => {
            return { label: commune.name, value: commune.id };
          })
        );
      })
      .catch((e) => console.log("error fetching the communes ", e));
  }, []);

  return (
    <div className="new-address-main">
      <div>
        <InputTextField
          title='Dirección'
          value={street_addr}
          name='street_addr'
          onChange={(e) => {
            setstreet_addr(e.target.value);
            changeValueBilling(e.target.value, "address");
          }}
          required={true}
          type='text'
        />
      </div>
      <div>
         <Select
          title='Comuna'
          value={commune}
          onChange={changeCommune}
          options={communes}
          key='commune-select-new'
        />
      </div>
      <div>
         <InputTextField
          title='Teléfono'
          value={phone}
          name='phone'
          onChange={(e) => {
            setphone(e.target.value);
            changeValueBilling(e.target.value, "phone");
          }}
          required={true}
          type='number'
        />
      </div>
      <div>
        <InputTextField
          title='Correo electrónico'
          value={email}
          name='email'
          onChange={(e) => {
            setemail(e.target.value);
            changeValueBilling(e.target.value, "email");
          }}
          required={true}
          type='email'
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changeShippingAddress(shipping_address) {
    dispatch({
      type: "CHANGE_SHIPPING_ADDRESS_VALUE",
      payload: {
        shipping_address,
      },
    });
  },
  resetShippingAddress() {
    dispatch({
      type: "RESET_SHIPPING_ADDRESS",
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewAddress);
