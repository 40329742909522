import React, { useState, useEffect } from 'react';
import './Input.css';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
} from '@material-ui/core';

function Input(props) {
  let { id, title, name, min, max, door_value } = props;
  const [error, setError] = useState(false);

  useEffect(() => {
    var input = document.getElementById(props.id);
    if(input.value === "") {

      input.classList.add('error');
      setError(true);
    }
  }, []);

  function changeInput() {
    let { changeSize } = props;
    changeSize();
  }

  function changeMaxMin(
    inputValue,
    value,
    changeValue,
    input,
    alternativeInput,
    type
  ) {
    if (inputValue < value) {
      input[type] = changeValue;
      alternativeInput[type] = value;
    } else {
      input[type] = value;
      alternativeInput[type] = changeValue;
    }
  }

  console.log('doors', door_value);

  function validateInput() {
    const inputElement = document.getElementById(props.id); // Replace 'id' with the actual ID of the input element
    const inputValue = parseFloat(inputElement.value);

    if (
      inputValue < parseFloat(props.min) ||
      inputValue > parseFloat(props.max) 
    ) {
      inputElement.classList.add('error');
      setError(true);
    } else {
      inputElement.classList.remove('error');
      setError(false);
    }
  }

  

  return (
    <div
      className="d-flex justify-content-between align-items-start"
      style={{ gap: '10px' }}
    >
      <span className="input-title mt-3"> {title} </span>
      <div className="form-group was-validated m-0" noValidate>
        {/* <FormControl fullWidth variant="outlined">
          <OutlinedInput
            error={min>door_value||door_value>max}
            id={id}
            name={name}
            onChange={ changeInput }
            type="number"
            inputProps={{ step: "1", style: { textAlign: "left" }}}
            endAdornment={<InputAdornment position="start">mm</InputAdornment>}
            labelWidth={0}
            placeholder='Ejem: 150'
            style={{ width: '170px' }}
          />
          <FormHelperText id="outlined-weight-helper-text">
          {
            title === 'Ancho' ? `Min: ${min} - máx: ${max}` : `Min: ${min} - máx: ${max}`
          }
          </FormHelperText>
        </FormControl> */}
        <div className="input-container">
          <input
            placeholder="Ejem: 150"
            className={`input-style ${error ? 'error' : ''}`}
            type="number"
            id={id}
            name={name}
            onChange={() => {
              changeInput();
              validateInput();
            }}
            min={Number(min)}
            max={Number(max)}
            
          ></input>
          <span>
            {title === 'Ancho'
              ? `Min: ${min} - máx: ${max}`
              : `Min: ${min} - máx: ${max}`}
          </span>
          <div className="input-mm">
            <span>mm</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Input;
