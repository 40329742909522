import React, { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import FillingSection from './FillingSection';
import { useParams } from 'react-router-dom';
import skus from '../../api/sku.json';
import es from '../../es.json';
import './Filling.scss';
import { connect } from 'react-redux';
import BottomButtons from '../../components/bottomButtons';
import { colorRestriction } from '../../api/restrictionSash';
import { getFillingSection } from '../../api/APIGet';
import { Tooltip, makeStyles } from '@material-ui/core';

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    border: '1px solid #F1F1F1',
    borderRadius: '4px',
    padding: '10px',
    fontSize: '14px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
}));

const Filling = ({
  history,
  dimensions,
  globalProfile,
  setRelleno,
  setSelectedLine,setSelectedLineSku,setFillerSku,
  changeUrlFillingPreview,
  ...props
}) => {
  const classes = useStylesBootstrap();
  const [fillers, setFillers] = useState([]);
  const [colors, setColor] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [localRelleno, setLocalRelleno] = useState('')
  const [arrayCategories, setArrayCategories] = useState([]);

  const XLCategories = { "categories":[
                        {
                          "code": "PUPUEPUEFAGRPUHVRHBT",
                          "name": "PUERTA XL - TREND",
                          "kind": "TREND"
                        },
                        {
                          "code": "PUPUEPUEFAGRPUZH9HBT",
                          "name": "PUERTA XL - BASIC",
                          "kind": "BASIC"
                        }]
                        };

                        /*console.log('FillingFetchedData =====>', dimensions)
                        console.log('Filling =====>', globalProfile);
                        const [arrayCategories, setArrayCategories] = useState([]);
                        console.log('Filling =====>', globalProfile);
  const [film, setFilm] = useState('');

  const XLCategories = { "categories":[
                        {
                          "code": "PUPUEPUEFAGRPUHVRHBT",
                          "name": "PUERTA XL - TREND",
                          "kind": "TREND"
                        },
                        {
                          "code": "PUPUEPUEFAGRPUZH9HBT",
                          "name": "PUERTA XL - BASIC",
                          "kind": "BASIC"
                        }]
                        }

  const getArrayCategory = () => {
    let { type} = props.match.params;
    let width = dimensions.width;
    let height = dimensions.height;
    let sumDimention = parseInt(width) + parseInt(height);
    if(width > 2100 || height > 2100){
      setArrayCategories(XLCategories.categories);
    }
    else{
      skus.data[type].forEach((element) => {
        if (element.value_min < sumDimention && sumDimention <= element.value_max) {
          setArrayCategories(element.categories);
        }
      });
    }
  };

  // Next Validation
  // Check if the user has selected at least one filling.
  // If filling is "Espejo", verifies if the user has selecetd black film option
  // Then ables the next button.
  //
  // @returns [Boolen] If the user can go to the next step.
  const nextValidation = () => {
    if(sku.trim() === ''){
      return true
    }
    else if(sku.trim() === 'RARAUES4M36025000HBT' && film === ''){
      return true
    }
    else{
      return false
    }
  }

  // Change Black Film
  // On click an option of the black film, changes the state of the film.
  // And change the class of the opposite button class to change its color.
  // @params event [Object] Event of the click.
  // @params available [Boolean] If the blackfilm is avaialable.
  //
  // @returns [Void] Changes the state of the film.
  const changeBlackFilm = (event, available) => {
    let buttonHash = { 'activateBlackFilm': 'deactivateBlackFilm', 'deactivateBlackFilm': 'activateBlackFilm' }
    let deactivateButton = document.getElementById(buttonHash[event.target.id])
    deactivateButton.classList.add('btn-outline-primary')
    deactivateButton.classList.remove('btn-primary')
    event.target.classList.remove('btn-outline-primary')
    event.target.classList.add('btn-primary')
    setFilm(available);
    props.updateFilm(available)
  }

  // Next History
  // Depeneding if the user has selected a filling or not, redirects to the next step.
  // 
  // @returns [Void] Redirects to the next step.
  const nextHistory = () => {
    if(sku !== 'RARAUES4M36025000HBT'){
      return `${location.pathname}/line/${line}/line_sku/${line_sku}/nameColor/${sku}/blackFilm/${false}`
    }
    else{
      return `${location.pathname}/line/${line}/line_sku/${line_sku}/nameColor/${sku}/blackFilm/${film}`
    }
  }

  const handleGreengrocer = () => {
    let { width, height } = dimensions;
    const total = parseInt(width) + parseInt(height);
    if (total <= 1400 && total >= 500) {
      props.changeGreengrocerOpt(true);
    } else {
      props.changeGreengrocerOpt(false);
    }
  };

  useEffect(() => {
    let { resetFilling, resetSash } = props;
    handleGreengrocer();
    resetFilling();
    resetSash();
    let fillingColor = document.getElementById("id-filling-color");
    getArrayCategory();
  }, [dimensions]);

  let selectFilling = [];
  let { location, filling, greengrocerOption } = props;
  let { line, sku, line_sku } = filling;

  if (arrayCategories.length !== 0) {
    selectFilling = arrayCategories.map((value, key) => {
      return (<FillingSection
        value={value}
        key={key}
        greengrocerOption={greengrocerOption}
        height={dimensions.height}
        width={dimensions.width}
      />)
    });
  }*/

  const getArrayCategory = useCallback(() => {
     let { type} = props.match.params;
    let width = dimensions.width;
    let height = dimensions.height;
    let sumDimention = parseInt(width) + parseInt(height);
    if(width > 2100 || height > 2100){
      setArrayCategories(XLCategories.categories);
    }
    else{
      skus.data[type].forEach((element) => {
        if (element.value_min < sumDimention && sumDimention <= element.value_max) {
          setArrayCategories(element.categories);
        }
      });
    }
  }, [dimensions.width, dimensions.height])

  function filterParentKeys(criteria) {
    setLoading(true);
    const parentKeys = new Set();
    const subKeys = new Set();

    for (const parentKey in colorRestriction) {
      for (const childKey in colorRestriction[parentKey]) {
        if (
          colorRestriction[parentKey][childKey][
            criteria?.split(' ')[0]
          ]
        ) {
          parentKeys.add(parentKey);
          subKeys.add(`4MM ${childKey}`);
        }
      }
    }

    setColor(Array.from(subKeys));

    // Create an array of promises for fetching data for each parent key
    const promises = Array.from(parentKeys).map(async (element) => {
      const response = await getFillingSection(element);

      return {
        fillers: response,
      };
    });

    // Use Promise.all to wait for all requests to complete
    Promise.all(promises)
      .then((results) => {
        const filteredResults = results.map((result) => {
          const fillerAttributes = result.fillers?.map(
            (filler) => filler?.attributes
          );
          return fillerAttributes;
        });

        const filling = filteredResults?.flat().map((filler) => {
          return {
            photos: filler?.photos,
            description: filler?.description,
            filler_sku : filler?.sku,
            kind : filler?.subfamily?.split(' ')[1],
          };
        
        });

        setFillers(filling);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }

  const restrictedSkusForXL = [
    "RERELCRIFARE5071NHBT", // CRISTAL 4MM BRONCE 2140X1650MM
    "ALPUECR4M360213BRHBT"  // CRISTAL 4MM BRONCE 2540X1605MM
  ];

  useEffect(() => {
    if (globalProfile !== '') {
      filterParentKeys(globalProfile);
      getArrayCategory();
    }
  }, [globalProfile]);

  const filteredFillers = fillers?.filter((filler) => {
    const isXLDoor = arrayCategories.some(category =>
      XLCategories.categories.some(xlCategory => xlCategory.code === category.code)
    );
    const isRestrictedSKU = restrictedSkusForXL.includes(filler?.filler_sku);
    return colors.includes(
      `${filler?.description?.split(' ')[1]} ${filler?.description?.split(' ')[2]}`
    ) && (!isXLDoor || !isRestrictedSKU);
  });

  const getLineSkuAndLine = (kind) => {
    const line_sku_and_line = arrayCategories?.filter(arrayCategory => arrayCategory?.kind === kind)
    setSelectedLine(line_sku_and_line?.[0]?.name)
    setSelectedLineSku(line_sku_and_line?.[0]?.code)
  }

  const FilterPhotosAndDescription = filteredFillers?.map(
    (filteredFiller) => {
      return filteredFiller?.photos?.map((photo) => {
        if (photo?.usage === 'FotoTecnica') {
          return (
            
                <div
          className={`main-warpper`}
          key={filteredFiller?.description}
                  onClick={() => {
                    setRelleno(
                      filteredFiller?.description
                        ?.split(' ')?.[2]
                        ?.toLowerCase()
                    );

                    setLocalRelleno(
                      filteredFiller?.description
                        ?.split(' ')?.[2]
                        ?.toLowerCase()
                    )

                    getLineSkuAndLine(filteredFiller?.kind)

                    setFillerSku(filteredFiller?.filler_sku)

                    changeUrlFillingPreview(photo?.url, "", "",filteredFiller?.filler_sku,filteredFiller?.description?.charAt(0).toUpperCase() + filteredFiller?.description?.slice(1)?.toLowerCase())
                    
                  }
                    
                  }
        >
          <Tooltip
            arrow
                    classes={classes}
                    title={filteredFiller?.description?.charAt(0).toUpperCase() + filteredFiller?.description?.slice(1)?.toLowerCase()}
                    placement="right"
          >
            <div
              className={`${
                localRelleno === filteredFiller?.description
                        ?.split(' ')?.[2]
                        ?.toLowerCase() ? 'selected-filling' : ''
              }`}

              style={{width: '56px',
                  height: '56px', display : "flex" , alignItems : "center", justifyContent: "center", marginRight : "auto", marginLeft : "auto" , cursor : "pointer"}}
            >
              <div
                style={{
                          backgroundImage: `url(${photo?.url})`,
                          width : "48px",
                          height : "48px",
                          border : "1px solid #ccc",
                          objectFit : "cover",
                        }}
              />
            </div>
          </Tooltip>
        </div>
      
          );
        }
      });
    }
  );

  
  return (
    <Container className="Filling">
      {Loading ? <div>Cargando Relleno</div> : FilterPhotosAndDescription}

      {/* <div id="blackFilm" className='d-none film-container'> 
        <div className='row'>
          <div className='col black-film'>
            <span className="title">{ es["blackFilm"]["question"] }</span>
          </div>
        </div>
        <div className='row py-2'>
          <div className='col black-film'>
            <span className="text">{ es["blackFilm"]["text"] }</span>
          </div>
        </div>
        <div className="d-flex justify-content-center" style={{ gap: '15px' }}>
          <button id="activateBlackFilm" className='btn btn-block btn btn-outline-primary mt-1' onClick={(e) => changeBlackFilm(e, true)}>{ es["common"]["yes"] }</button>
          <button id="deactivateBlackFilm" className='btn btn-block btn btn-outline-primary mt-1' onClick={(e) => changeBlackFilm(e, false)}>{ es["common"]["no"] }</button>
        </div>
      </div> */}

      {/* <Row>
        <BottomButtons
          buttonClassName='col-6 col-lg-3 mb-3'
          onBackLink={`/client/type/${type}/open/${open}`}
          onNext={() =>
            history.push(nextHistory())
          }
          disabledNext={ nextValidation() }
        />
      </Row> */}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  filling: state.filling,
  greengrocerOption: state.greengrocerOption,
  open: state.open,
  size: state.size,
  dimensions: state.dimensions,
  fillingFetchedData: state.fillingFetchedData,
});

const mapDispatchToProps = (dispach) => ({
  changeUrlFillingPreview(url, sku, line, sku_section, description) {
    dispach({
      type: "CHANGE_FILLING",
      url,
      line,
      line_sku: sku_section,
      sku,
      description,
    });
  },
  resetFilling() {
    dispach({
      type: 'RESET_FILLING',
    });
  },
  resetSash() {
    dispach({
      type: 'RESET_SASH',
    });
  },
  changeGreengrocerOpt(opt) {
    dispach({
      type: 'CHANGE_GREENGROCER_OPT',
      opt,
    });
  },
  updateFilm(film) {
    dispach({
      type: 'CHANGE_FILM',
      film: film,
    });
  },
});

const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(Filling);

export default component;
