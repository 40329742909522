import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import DoorToOpen from "../../components/doorToOpen/DoorToOpen";
import "./index.css";
import { connect } from "react-redux";
import BottomButtons from "../../components/bottomButtons";
import Sidebar from "../../components/sideBar/SideBar";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Accordion from "react-bootstrap/Accordion";

const Open = ({ open, history, ...props }) => {
  useEffect(() => {
    let { resetOpen, resetFilling, resetSash, resetSize } = props;
    resetOpen();
    resetFilling();
    resetSash();
    resetSize();
  }, []);

  const [currentActive, setCurrentActive] = useState(["0"]);
  const imgs = {
    left: "/images/default_izquierda.png",
    right: "/images/default_derecha.png",
    bottom: "/images/default_inferior.png",
    top: "/images/default_superior.png",
    avento: "/images/default_plegable.png",
    slider: "/images/default_corredera.png",
  };
  const [defaultImage, setDefaultImage] = useState(imgs["left"]);
  const [isFirstTime, setIsFirstTime] = useState(0);

  useEffect(() => {
    setDefaultImage(imgs[open.toLowerCase()]);
  }, [open]);

  return (
    <Row className="h-100">
      <Sidebar
        onNext={() => history.push(`open/${open}`)}
        onBack={() => history.push("/")}
        width="col-3"
        disabledNext={open === ""}
        >
        <Accordion
          className="d-flex flex-column align-items-center border-0"
          activeKey={currentActive}
          defaultActiveKey={["0"]}
          alwaysOpen
          style={{ width: "90%" }}>
          <Accordion.Item className="w-100" eventKey="0">
            <Accordion.Header
              className="d-flex justify-content-between align-items-center mt-4 mt-lg-5 w-100"
              style={{ cursor: "pointer" }}
              onClick={() => {
                currentActive != "0"
                  ? setCurrentActive("0")
                  : setCurrentActive("1");
              }}>
              <span
                className="font-weight-bold"
                style={{ color: "black", fontSize: "18px", marginLeft: "6px" }}>
                TIPO DE APERTURA
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <Row className="d-flex mt-2 mt-lg-3 mb-0 mb-lg-0 step-one-options">
                <Col
                  xs="12"
                  lg="12"
                  className="d-flex justify-content-start mb-4 px-0">
                  <DoorToOpen id="Left" name="Izquierda" />
                </Col>
                <Col
                  xs="12"
                  lg="12"
                  className="d-flex justify-content-start mb-4 px-0">
                  <DoorToOpen id="Right" name="Derecha" />
                </Col>
                <Col
                  xs="12"
                  lg="12"
                  className="d-flex justify-content-start mb-4 px-0">
                  <DoorToOpen id="Bottom" name="Inferior" />
                </Col>
                <Col
                  xs="12"
                  lg="12"
                  className="d-flex justify-content-start mb-4 px-0">
                  <DoorToOpen id="Top" name="Superior" />
                </Col>
                <Col
                  xs="12"
                  lg="12"
                  className="d-flex justify-content-start mb-4 px-0">
                  <DoorToOpen id="Avento" name="Plegable" />
                </Col>
                <Col
                  xs="12"
                  lg="12"
                  className="d-flex justify-content-start mb-4 px-0">
                  <DoorToOpen id="Slider" name="Corredera" />
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Sidebar>
      <div
        className="col-9 p-0 m-0 d-flex justify-content-center align-items-center"
        style={{ height: "100%", position: "relative" }}>
       
        {open === "" ? (
          <>
            <div
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                backgroundColor: "black",
                zIndex: "2",
                opacity: "0.3",
              }}></div>

            <img
              src="/coleccion_bg.jpg"
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: "0px",
                left: "0px",
              }}
            />
             <div
          className="d-flex flex-column align-items-center"
          style={{ gap: "16px", zIndex: "4" }}>
          <VisibilityIcon fontSize="large" style={{ color: "white" }} />
          <span
            className="text-white text-center" 
            style={{ maxWidth: "400px", fontWeight: "900" }}>
            POR FAVOR ESCOGE EL TIPO DE PUERTA PARA COMENZAR NUESTRA SIMULACION
          </span>
        </div>
           
          </>
        ) : (
          <>
            <div
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                backgroundColor: "black",
                zIndex: "2",
                backgroundColor: "#f1f1f1",
              }}></div>
            <img
              src={defaultImage}
              alt="default_back_image"
              width={"80%"}
              style={{ zIndex: "10" }}
            />
          </>
        )}
      </div>
    </Row>
  );
};
const mapStateToProps = (state) => ({
  open: state.open,
});

const mapDispatchToProps = (dispach) => ({
  resetOpen() {
    dispach({
      type: "RESET_OPEN",
    });
  },
  resetFilling() {
    dispach({
      type: "RESET_FILLING",
    });
  },
  resetSash() {
    dispach({
      type: "RESET_SASH",
    });
  },
  resetSize() {
    dispach({
      type: "RESET_SIZE",
    });
  },
});

const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(Open);

export default component;
