import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { formatDescription } from "../../helpers";
import { Tooltip } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    border: '1px solid black',
    padding: '8px'
  },
}));

const AllColor = ({
  filler
}) => {
  // history match
  // const { history } = useHistory();

  // const { open, width, height, type } = useParams();

  const classes = useStylesBootstrap();

  /*const fillingColorSelected = (url, sku, id, description) => {
    // document.querySelector('#blackFilm').classList.add('d-none')
    if (sku === "ALPUEPLAC12518500HBT") {
      changeUrlFillingPreview(
        url,
        sku,
        "PUERTA VERDULERA SMALL HASTA 450 X 900",
        "ALPUEPUEVESM45X90HBT",
        description
      );
    } 
    else if(sku == "RARAUES4M36025000HBT"){
      // document.querySelector('#blackFilm').classList.remove('d-none')
      changeUrlFillingPreview(url, sku, line, sku_section, description)
    }
    else {
      changeUrlFillingPreview(url, sku, line, sku_section, description)
    }
  };

  let colection = collection.map((value, key) => {
    const isSelected = () => {
      return value.sku === filling.sku;
    };
    return (
      <div className="col-3 p-0">
        <Tooltip arrow classes={classes} title={formatDescription(value.description, value.sku)} placement="right">
          <div
            key={key}
            id={`container-${key}-${line}`}
            className={`container-filling-color text-center`}
            
          >
            <span
              id={`${line}-${value.sku}`}
              className={`border border-container`}
              style={{ backgroundImage: `url(${value.url})` }}
            />
            
          </div>
        </Tooltip>
      </div>
    );
  });*/

  console.log('filler', filler)

  return (
    <>
    
      
  
    </>
  )
};

const mapStateToProps = (state) => ({
  filling: state.filling,
});

const mapDispatchToProps = (dispach) => ({
  changeUrlFillingPreview(url, sku, line, sku_section, description) {
    dispach({
      type: "CHANGE_FILLING",
      url,
      line,
      line_sku: sku_section,
      sku,
      description,
    });
  },
});

const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(AllColor);

export default component;
