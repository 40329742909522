const defaultState = false

function reducer(state = defaultState, {type, opt}) {
  let stateValue = '';
  switch (type) {
    case 'CHANGE_GREENGROCER_OPT': {
      stateValue = opt
      break;
    }
    case 'RESET_GREENGROCER': {
      stateValue = false;
      break;
    }
    default:
      stateValue = state;
      break;
  }
  return stateValue
}

export default reducer;