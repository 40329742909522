const defaultState = ''



export default function reducer(state = defaultState, {type, profile_color}) {
 let stateValue = '';
  switch (type) {
    case 'CHANGE_PROFILE_COLOR': {
      stateValue = profile_color
      break;
    }
    default:
      stateValue = state;
      break;
  }
  return stateValue
}