const defaultState = {
  id: "install3",
  text: "Quiero embalaje normal sin instalación",
  cost: 0,
  sku: 0
};

function reducer(state = defaultState, { type, select_type }) {
  let selected = {};
  switch (type) {
    case "SELECT_TYPE": {
      selected = select_type;
      break;
    }
    default:
      selected = state;
      break;
  }
  return selected;
}

export default reducer;
