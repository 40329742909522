import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Route, Link } from 'react-router-dom';
import ListDoors from './listDoors/ListDoors';
import DeliveryProduct from './deliveryProduct/DeliveryProduct';
import ExtendBuy from './extendBuy/ExtendBuy';
import PersonalData from './personalData/PersonalData';
import { connect } from 'react-redux';
import './Cart.scss';
import { FormControl } from '@material-ui/core';
import { createWorkOrder } from '../../api/APIPost';
import { toast } from 'react-toastify';
const { validate } = require('rut.js');

class Cart extends Component {
  constructor() {
    super();
    this.state = { deliveryPrice: 0, errors: true };
    this.resetBuy = this.resetBuy.bind(this);
    this.finishBuy = this.finishBuy.bind(this);
    this.getDataClient = this.getDataClient.bind(this);
    this.setDeliveryPrice = this.setDeliveryPrice.bind(this);
    this.setErrors = this.setErrors.bind(this);
  }

  resetBuy() {
    let { resetProductCart, resetHinge } = this.props;
    resetProductCart();
    resetHinge();
  }

  // get all data of client in the imputs
  getDataClient() {
    let { statePersonal, stateSelectCommune } = this.props;
    let formArray = document.querySelectorAll(
      '#form-personal-data input[type=text],input[type=email],input[type=number]'
    );
    var returnArray = {};
    for (var i = 0; i < formArray.length; i++) {
      returnArray[formArray[i]['name']] = formArray[i]['value'];
    }
    returnArray.old = statePersonal;
    returnArray.commune = stateSelectCommune;
    return returnArray;
  }

  setDeliveryPrice(newValue) {
    this.setState({ deliveryPrice: newValue });
  }

  setErrors(value) {
    this.setState({ errors: value });
  }

  // finish all proccess, create new work order pending to be buy
  finishBuy(event) {
    event.preventDefault();
    let { cart} = this.props;
    const MAX_CART_ITEMS = 20;
    let skuList = new Set();
    let skus = [];
    cart.product.map(async (door) => {
      skuList.add(door.line_sku);
      skus.push(door.line_sku);
    });

    if (skuList.size > MAX_CART_ITEMS) {
      alert(
        `Oye, estás cotizando ahora mismo ${skuList.size} sku, el límite es ${MAX_CART_ITEMS}, reduce esta cotización a ${MAX_CART_ITEMS} y el resto debe hacerse en otra cotización.`
      );
      return;
    }

    if (!this.state.errors) {
      document.getElementById('cotizar-button').disabled = true;

      setTimeout(() => {
        document.getElementById('cotizar-button').disabled = false;
      }, 2000);
    }

    let dataClient = this.getDataClient();
    if (
      dataClient.commune.length !== 0 &&
      this.props.deliveryInformation.shipping_address.commune !== ''
    ) {
      let ref = document.getElementById('invoice_ref_id');
      if (validate(ref.value)) {
        let { cart, history, deliveryInformation } = this.props;
        let products = [];
        // encapsulate all data of product ass hinge, size, etc
        cart.product.forEach((data) => {
          let product = Object.keys(data).map((key) => {
            return { field_name: key, value: data[key] };
          });
          products.push(product);
        });
        if (deliveryInformation.packaging_sku === '0') {
          deliveryInformation.packing = 'no-packaging';
        } else {
          deliveryInformation.packing = 'with-packaging';
        }
        // post to create new work order
        createWorkOrder(
          products,
          dataClient,
          deliveryInformation
        ).then((response) => {
          this.resetBuy();
          history.push('/client/finished', { ...response });
        });
      } else {
        toast.error('Rut no válido', { autoClose: 2000 });
      }
    } else {
      toast.error('Ingrese una comuna', { autoClose: 2000 });
    }
  }

  // Check XL Door
  // Check if the cart has a XL door.
  // @params products [Array] Array of products (Hash) in the cart.
  // @returns [Boolean] true if the car has a XL door, otherwise it will return false.
  checkXLDoor(products) {
    let filtered_doors = products.filter(
      (product) => product['height'] > 2100 || product['base'] > 2100
    );
    return filtered_doors.length > 0;
  }

  render() {
    let { cart } = this.props;
    if (cart.product.length === 0) {
      return (
        <Container>
          <Row>
            <Col xs="12" className="text-center">
              <h2 className="mt-4">No hay productos agregados</h2>
            </Col>
          </Row>
        </Container>
      );
    }

    return (
      <div
        className="Cart w-100"
        style={{ backgroundColor: '#F1F1F1' }}
      >
        <form onSubmit={this.finishBuy}>
          <FormControl required id="form-personal-data">
            <Row className="my-4">
              <Col xs="12" md="8" className="text-center">
                <div
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    padding: '25px 10px',
                  }}
                >
                  <Route
                    path="/client/cart/buy"
                    component={PersonalData}
                  />
                  <Route path="/client/cart/buy">
                    <DeliveryProduct
                      setDeliveryPrice={this.setDeliveryPrice}
                      setErrors={this.setErrors}
                      doorXL={this.checkXLDoor(cart.product)}
                    />
                  </Route>
                </div>
              </Col>
              <Col xs="12" md="4" className="text-center">
                <div
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    padding: '20px 0px',
                  }}
                >
                  <Route
                    path="/client/cart/buy"
                    component={ListDoors}
                  />
                  <Route path="/client/cart/buy">
                    <ExtendBuy
                      setDeliveryPrice={this.setDeliveryPrice}
                      deliveryPrice={this.state.deliveryPrice}
                      setErrors={this.setErrors}
                      errors={this.state.errors}
                    />
                  </Route>
                </div>
              </Col>
            </Row>
          </FormControl>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cart: state.cart,
  buySelected: state.buySelected,
  statePersonal: state.statePersonal.stateValue,
  personalData: state.statePersonal.personalData,
  stateSelectCommune: state.stateSelectCommune,
  deliveryInformation: state.deliveryInformation,
});

const mapDispatchToProps = (dispach) => ({
  resetProductCart() {
    dispach({
      type: 'RESET_PRODUCT',
    });
  },
  resetHinge() {
    dispach({
      type: 'RESET_STATE',
    });
  },
});

const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(Cart);

export default component;
