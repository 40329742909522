import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";

import "./DeliveryProduct.scss";
import { connect } from "react-redux";
import InputSelectField from "../../../components/InputSelectField";
import Select from "../../../components/select";
import { getProductPrices, getMaterial, getStores } from "../../../api/APIGet";
import NewAddress from "./NewAddress";
import PopoverComponent from "../../../components/popover";
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import RoomIcon from '@material-ui/icons/Room';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ShippingIcon from '../../../img/shipping.svg'

const defaultDeliveryOptions = [
  {
    label: "Usar la dirección de facturación",
    value: "facturation",
  },
  { label: "Añadir una nueva dirección", value: "new" },
];

const DeliveryProduct = ({
  addresses,
  priceList,
  resetPersonalData,
  changeKind,
  changePackagingSku,
  changeShippingAddress,
  selectStoreDelivery,
  deliveryInformation: { kind, packaging_sku, shipping_address },
  billing_address,
  logistic_train,
  doorXL,
  ...props
}) => {

  const [address, setAddress] = useState("facturation");
  const [showNewAddress, setShowNewAddress] = useState(false);
  const [packagingDescription, setPackagingDescription] = useState();
  const [stores, setStores] = useState([])
  const [storePrices, setStorePrices] = useState([])
  const [selectedStore, setSelectedStore] = useState(0)

  // The packaging options are setted depending if the user is quoting a XL door.
  // The next [HASH] is the default packaging option.
  let packagingOptions = [
    {
      label: "Si, quiero embalaje especial.",
      value: "ALPUEEMSOLFAB0031HBT"
    }
  ]

  // Reinforced Packaging
  // 
  // Verifies if the current cart needs to have reinforced packaging
  // The conditions to bind the reinforced packaging are:
  // 1.- The door is XL
  // 2.- The user has the value 'S' in logistric train field
  // 3.- The user hasn't selected as delivery option 'store
  // @returns [Boolean] If the current cart needs reinforced packaging
  const reiforcedPackaging = () => {
    if(selectedStore !== 0 && kind === 'store'){
      return selectedStore !== 1
    }
    return (doorXL || logistic_train === 'S' || ['ship', 'logistic_train'].includes(kind))
  }

  // If there is no XL door in the cart, the next packaging option is added.
  if(!reiforcedPackaging()){
    packagingOptions.push({label: "No, no usaré el embalaje especial.", value: "0" })
  }

  useEffect(() => {
    if (kind === "store") {
      setShowNewAddress(false);
    } else {
      setShowNewAddress(address === "new");
    }
  }, [kind]);

  const loadStoreOptions = async () => {
    let options = [{value: 0, label: 'Seleccione una Tienda'}]
    let newStorePrices = []
    await getStores().then((response) => {
      response.forEach((store) => {
        let id = store.attributes.id
        let price = +store.attributes.door_price
        newStorePrices.push({id: id, price: price})
        options.push({
          label: store.attributes.name,
          value: store.attributes.id,
        })
      })
      setStorePrices(newStorePrices)
      setStores(options) 
    })
  }

  const getPackagingInfo = async () => {
    await getMaterial("ALPUEEMSOLFAB0031HBT").then((response) => {
      setPackagingDescription(response);
    });
  };

  useEffect(() => {
    loadStoreOptions();
    getPackagingInfo();
    return () => {
      resetPersonalData();
    };
  }, []);

  useEffect(() => {
    if (address === "facturation") {
      changeShippingAddress({ ...billing_address, kind: "same" });
    }
  }, [billing_address]);

  const formatOptions = () => {
    return addresses.map((address) => {
      return {
        value: address.id,
        label: address.address,
      };
    });
  };

  const selectStore = (event) => {
    let price = storePrices.filter(store => store.id === event)[0]
    props.setErrors(event === 0)
    setSelectedStore(event)
    if(event === 0){return}
    props.setDeliveryPrice(price.price)
    selectStoreDelivery(price)
  }

  const changeDeliveryMethod = (newMethod) => {
    changeKind(newMethod)
    if(newMethod != 'store'){
      setSelectedStore(0)
      selectStoreDelivery({id: 0, price: 0})
      props.setErrors(false)
    }else{
      props.setErrors(true)
    }
  }

  const changeAddress = (value) => {
    setAddress(value);
    setShowNewAddress(value === "new");
    if (value === "facturation") {
      if (billing_address) {
        changeShippingAddress({ ...billing_address, kind: "same" });
      }
    } else if (value === "new") {
      setShowNewAddress(true);
      changeShippingAddress({ ...billing_address, kind: "new" });
    } else {
      setAddress(value);
      const address = addresses.find(
        (addressToFind) => addressToFind.id === value
      );
      changeShippingAddress({ ...address, kind: "other" });
    }
  };

  const renderSelectedStores = () => {
    return (
      <div>
        <div className="selectedStore">
          <div>
            <Select
            title='¿Deseas embalar tus productos?'
            value={packaging_sku}
            options={packagingOptions}
            onChange={changePackagingSku}
            disabled={reiforcedPackaging()}
            name='Packaging-select'
          />
          </div>
          <div>
            <Select
            title='Seleccione la tienda'
            value={selectedStore}
            options={stores}
            onChange={selectStore}
            name='store-select'
          />
          </div>
        </div>
          {packagingDescription && (
          <PopoverComponent
            text={packagingDescription.attributes.description}
            titleComponent={() => (
              <Col lg='12' md='12' xs='12' className='packing-question'>
                <>
                <p className='ml-3' style={{ color: '#707070' }}>
                  ¿Qué es el embalaje especial?{" "}
                  <i className='fa fa-question-circle-fas' />
                </p>
                </>
              </Col>
            )}
          />
        )}
      </div>
    );
  }

  const renderPackagingInfo = () => {
    return (
      <Row style={{marginTop : '10px'}}>
        <Col lg='12' md='12' xs='12' className="packagingInfo">
          <Select
            title='Dirección de despacho'
            value={address}
            onChange={changeAddress}
            options={formatOptions()}
            defaultOptions={defaultDeliveryOptions}
            key='Delivery-select'
            name='Delivery-select'
          />
          <Select
            title='¿Deseas embalar tus productos?'
            value={packaging_sku}
            options={packagingOptions}
            onChange={changePackagingSku}
            disabled={reiforcedPackaging()}
            name='Packaging-select'
          />
        </Col>
        {packagingDescription && (
          <PopoverComponent
            text={packagingDescription.attributes.description}
            titleComponent={() => (
              <Col lg='12' md='12' xs='12' className='packing-question'>
                <>
                <p className='ml-2'>
                  ¿Qué es el embalaje especial?{" "}
                  <i className='fa fa-question-circle-fas' />
                </p>
                </>
              </Col>
            )}
          />
        )}
      </Row>
    );
  };

  const renderDeliveryOptions = () => {
    if (logistic_train == 'N') {
      return (
        <Col className='delivery-options col-12 mt-3'>
          <Col className='col-6'>
            <div
              onClick={() => changeDeliveryMethod("ship")}
              className={`delivery-buttons ${kind === 'ship' ? 'active' : 'deactive'}`}
              style={{ cursor: 'pointer' }}
            >
              <div className="w-100 content">
                <CheckCircleOutlinedIcon /> Despacho a domicilio
              </div>
              <div className="d-flex justify-content-center align-items-center" style={{ width: '60px', height: '60px' }}>
                <RoomIcon style={{ height: '30px' }}/>
              </div>
            </div>
          </Col>
          <Col className='col-6'>
            <div
              onClick={() => changeDeliveryMethod("store")}
              className={`delivery-buttons ${kind === 'store' ? 'active' : 'deactive'}`}
            >
              <div className="w-100 content">
                <CheckCircleOutlinedIcon /> Retiro en Tienda
              </div>
              <div className="d-flex justify-content-center align-items-center" style={{ width: '60px', height: '60px' }}>
                {/* <LocalShippingIcon style={{ height: '30px' }}/> */}
                <img src={ShippingIcon} height={30} />
              </div>
            </div>
          </Col>
        </Col>
      )
    }else {
      return (
        <Col className='delivery-options col-12'>
          <Col className='col-4'>
            <Button
              onClick={() => changeDeliveryMethod("store")}
              className='btn-block'
              variant={kind === "store" ? "primary" : "outline-primary"}
            >
              Retiro en Tienda
            </Button>
          </Col>
          <Col className='col-4'>
            <Button
              onClick={() => changeDeliveryMethod("ship")}
              variant={kind === "ship" ? "primary" : "outline-primary"}
              className=' btn-block'
            >
              Despacho a domicilio
            </Button>
          </Col>
          <Col className='col-4'>
            <Button
              onClick={() => changeDeliveryMethod("logistic-train")}
              variant={kind === "logistic-train" ? "primary" : "outline-primary"}
              className=' btn-block'
            >
              Despacho programado
            </Button>
          </Col>
        </Col>
      );
    }
  }

  const renderNewAddress = () => {
    return <NewAddress />;
  };

  return (
    <Row>
      <span className='mt-4 text-left col-12' style={{ fontWeight: 'bold' }}>2. TIPO DE DESPACHO</span>
      {renderDeliveryOptions()}
      <Col md='12' lg='12' xs='12'>
        {kind === "store" ? renderSelectedStores() : renderPackagingInfo() }
      </Col>
      {showNewAddress && (
        <Col md='12' lg='12' xs='12'>
          {renderNewAddress()}
        </Col>
      )}
    </Row>
  );
};

const mapStateToProps = (state) => ({
  addresses: state.statePersonal.addresses,
  priceList: state.statePersonal.personalData.price_list,
  deliveryInformation: state.deliveryInformation,
  billing_address: state.statePersonal.personalData.billing_address,
  logistic_train: state.statePersonal.personalData.logistic_train
});

const mapDispatchToProps = (dispatch) => ({
  changeKind(kind) {
    dispatch({
      type: "CHANGE_KIND_SHIPPING",
      payload: { kind },
    });
  },
  changePackagingSku(packaging_sku) {
    dispatch({
      type: "CHANGE_PACKAGING_SKU",
      payload: {
        packaging_sku,
      },
    });
  },
  changeShippingAddress(shipping_address) {
    dispatch({
      type: "CHANGE_SHIPPING_ADDRESS",
      payload: {
        shipping_address,
      },
    });
  },
  selectStoreDelivery(store){
    dispatch({
      type: "SELECT_STORE",
      payload: {
        id: store.id,
        price: store.price,
      },
    });
  },
  resetPersonalData() {
    dispatch({
      type: "RESET_STATE_PERSONAL_DATA",
    });
  },
});

const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(DeliveryProduct);

export default component;
