import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './HBTModal.css';

function HBTModal({ isModalOpen, setIsModalOpen }) {
  return (
    <Modal
      show={isModalOpen}
      onHide={() => setIsModalOpen(!isModalOpen)}
      size="lg"
    >
      <Modal.Body style={{borderRadius : "30px"}}>
        <div className="terms-and-condition-wrapper">
          <h4 style={{ fontSize: '16px', textAlign: 'center' }}>
            Términos y Condiciones
          </h4>
          <div>
            <dl>
              <dt
                style={{
                  fontSize: '14px',
                  color: '#000',
                  fontWeight: '700',
                }}
              >
                Condiciones respecto a la fabricación:
              </dt>
              <dd
                style={{
                  fontSize: '14px',
                  color: '#000',
                  fontWeight: '400',
                  marginBottom: '0.5rem',
                  lineHeight: '1.5',
                }}
              >
                Todo servicio ingresa a producción una vez tenga
                listado de cortes y este facturado.
              </dd>
              <dd
                style={{
                  fontSize: '14px',
                  color: '#000',
                  fontWeight: '400',
                  marginBottom: '0.5rem',
                  lineHeight: '1.5',
                }}
              >
                Plazo de fabricación (7) días hábiles.
              </dd>
              <dd
                style={{
                  fontSize: '14px',
                  color: '#000',
                  fontWeight: '400',
                  marginBottom: '0.5rem',
                  lineHeight: '1.5',
                }}
              >
                El plazo de fabricación empieza a correr al día
                siguiente que se factura{' '}
              </dd>
              <dd
                style={{
                  fontSize: '14px',
                  color: '#000',
                  fontWeight: '400',
                  marginBottom: '0.5rem',
                  lineHeight: '1.5',
                }}
              >
                Una vez facturado el servicio no hay posibilidad de
                hacer cambios en el listado de cortes.
              </dd>
              <dt
                style={{
                  fontSize: '14px',
                  color: '#000',
                  fontWeight: '700',
                }}
              >
                Condiciones de recepción y entrega:
              </dt>
              <dd
                style={{
                  fontSize: '14px',
                  color: '#000',
                  fontWeight: '400',
                  marginBottom: '0.5rem',
                  lineHeight: '1.5',
                }}
              >
                El cliente deberá comprobar dimensiones y cantidad de
                cortes inmediatamente se hace la recepción de los
                frentes.
              </dd>
              <dd
                style={{
                  fontSize: '14px',
                  color: '#000',
                  fontWeight: '400',
                  marginBottom: '0.5rem',
                  lineHeight: '1.5',
                }}
              >
                En caso de encontrar algún frente con medidas
                diferentes respecto a las entregadas en la OT , esta
                pieza sera restituida sin costo en un plazo máximo de
                2 días hábiles.
              </dd>
              <dd
                style={{
                  fontSize: '14px',
                  color: '#000',
                  fontWeight: '400',
                  marginBottom: '0.5rem',
                  lineHeight: '1.5',
                }}
              >
                El cliente deberá reportar en el momento de la
                recepción cualquier inconformidad respecto al estado
                de los frentes recibidos, una vez firmada la guía no
                se admitirán reclamos ya que los frentes pasan a ser
                responsabilidad del cliente.
              </dd>
              <dd
                style={{
                  fontSize: '14px',
                  color: '#000',
                  fontWeight: '400',
                  marginBottom: '0.5rem',
                  lineHeight: '1.5',
                }}
              >
                Una vez recibidos a conformidad los frentes cualquier
                modificación se considerara como un nuevo servicio.
              </dd>
              <dt
                style={{
                  fontSize: '14px',
                  color: '#000',
                  fontWeight: '700',
                }}
              >
                Condiciones de embalaje:
              </dt>
              <dd
                style={{
                  fontSize: '14px',
                  color: '#000',
                  fontWeight: '400',
                  marginBottom: '0.5rem',
                  lineHeight: '1.5',
                }}
              >
                Los cortes se entregaran en un pallet debidamente
                embalados, numerados e identificables.
              </dd>
              <dd
                style={{
                  fontSize: '14px',
                  color: '#000',
                  fontWeight: '400',
                  marginBottom: '0.5rem',
                  lineHeight: '1.5',
                }}
              >
                Los residuos de tableros y tapacanto serán devueltos
                al cliente para su aprovechamiento y posteriores
                nuevos servicios.
              </dd>
            </dl>
          </div>
          <div className="modal-button-wrapper">
            <button
              onClick={() => setIsModalOpen(!isModalOpen)}
              className="btn btn-primary"
              style={{
                paddingTop: '1rem',
                paddingRight: '2.4rem',
                paddingBottom: '1rem',
                paddingLeft: '2.4rem',
                width: '50%',
              }}
            >
              CERRAR
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default HBTModal;
