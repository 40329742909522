import { connect } from "react-redux";
import React, { Component, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { global } from "../../../api/data";
import axios from "axios";
import ItemDoors from "./ItemDoors";

const ListDoors = ({ cart, ...props }) => {

  const getMaterialAsync = (sku) => {
    let url = `${global.api_pim.url}/products/${sku}`;
    return axios.get(url, { headers: global.api.header });
  };

  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getDoors = async () => {
      let products = [];
      await Promise.all(
        cart.product.map(async (door) => {
          let item = {};
          await getMaterialAsync(door.sash_sku).then((response) => {
            item = { ...item, sash_attributes: response.data.data.attributes };
          }).catch((err) => { console.log(err) });
          await getMaterialAsync(door.filling_sku).then((response) => {
            console.log('PHOTO ===>', response.data.data.attributes.photos)
            let photoFill = response.data.data.attributes.photos.find(
              (photo) => photo.usage === "FotoAmbientada"
            );
            if (photoFill) {
              photoFill = photoFill.url;
            }
            item = {
              ...item,
              urlColor: photoFill,
              filling_attributes: response.data.data.attributes,
            };
          }).catch((err) => {console.log(err) });
          let hinges = "";
          door.hinge_positions.forEach((element, key) => {
            hinges = key === 0 ? element.value : `${hinges}x${element.value}`;
          });
          item = {
            ...item,
            ...door,
            hinges,
          };
          products.push(item);
        })
      );
      console.log(products.length)
      console.log(products)
      setProductList(products);
      setLoading(false);
    };
    getDoors();
  }, [cart.product]);

  const renderProducts = () => {
    if (loading) {
      return <div> Cargando ... </div>;
    } else {
      return productList.map((door, index) => {
        return <ItemDoors {...door} key={`List-item-door-${door.id}`} />;
      });
    }
  };

  return (
    <Container>
      <Row>
        <span style={{ fontWeight: 'bold', textAlign: 'left' }}> RESUMEN DE PEDIDO </span>
        <div className='list-group col-12 cart-doors-products border-bottom'>
          {renderProducts()}
        </div>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  cart: state.cart,
  array_price: state.cart.array_price,
  personalData: state.statePersonal.personalData,
  listDoorsItems: state.listDoorsItems,
});

const mapDispatchToProps = (dispach) => ({
  addDoorsCar(doors) {
    dispach({
      type: "ADD_LIST_DOORS",
      payload: { doors },
    });
  },
  resetItemOfCar() {
    dispach({
      type: "RESET_LIST_DOORS",
    });
  },
  addPriceListCar(price) {
    dispach({
      type: "ADD_ARRAY_PRICE",
      price,
    });
  },
  resetPriceListCar() {
    dispach({
      type: "RESET_ARRAY_PRICE",
    });
  },
});

const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(ListDoors);

export default component;
