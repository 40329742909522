const defaultState = {
  url: "",
  name: "",
  sku: "",
  previewPhoto: "//img.hbt-api.com/img/Perfil_Omnia.jpg",
};

function reducer(state = defaultState, { type, url, name, sku, previewPhoto }) {
  let stateValue = {
    url: "",
    name: "",
    sku: "",
    previewPhoto: "//img.hbt-api.com/img/Perfil_Omnia.jpg",
  };
  switch (type) {
    case "CHANGE_SASH": {
      return { ...state, url, name, sku, previewPhoto };
    }
    case "RESET_SASH": {
      stateValue = {
        url: "",
        name: "",
        sku: "",
        previewPhoto: "//img.hbt-api.com/img/Perfil_Omnia.jpg",
      };
      break;
    }
    default:
      stateValue = state;
      break;
  }
  return stateValue;
}

export default reducer;
