import React, { useState } from 'react'
import { Accordion, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

import Sidebar from '../../components/sideBar/SideBar';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { toast } from "react-toastify";
import "./index.css"

import text from '../../es.json';

const makeid = (length) => {
  var result = "";
  var characters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};


const Resumen = ({ history, sash, filling, profile_color , ...props }) => {
  
  let { addProductCart, location, mechanized, height_furniture='' } = props
  let {
    open, width, height,line,line_sku, nameColor, profile,hinge: hinges, repeat, type, blackFilm
  } = useParams()
  
  console.log("resumen", sash)
  console.log("Valor de mechanized:", mechanized);
  
  const getHinges = () => {
    if (hinges === "noHinges") return [];
    else
      return hinges?.split("x")?.map((val) => {
        return { value: val.split("|")[0], section: val.split("|")[1] };
      });
  };
  
  const getHingeString = () => {
    const hingArray = getHinges();
    var hingObjects = {};
    for(var i = 0 ; i < hingArray.length ; i ++) {
      hingObjects[hingArray[i].section] = hingArray[i].value
    }
    const list = ['A', 'B', 'C', 'D', 'E']
    var displayString = '';
    for(var i = 0 ; i < list.length ; i ++) {
      if(hingObjects[list[i]]) {
        displayString = displayString + `${list[i]}: ${hingObjects[list[i]]}`
      } else {
        displayString = displayString + `${list[i]}: 0`
      }
      if(i !== list.length - 1) {
        displayString = displayString + ' - '
      }
    }
    return displayString
  }

  

  const [currentActive, setCurrentActive] = useState(["0"]);

  let result = [
    { 
      label: "Stylo/Off", 
      value: "35MM_HINGE", 
    },
    { label: "Omnia/Mini", 
      value: "OMNIA_HINGE",
    },
    {
      label: "Omnia/Mini con Blumotion",
      value: "BLUEMOTION_HINGE"
    },
    { label: "Sin mecanizado", 
      value: "NO_MECHANIZED",
    },
    { label: "Omnia/Mini y HK-XS", 
      value: "HK_XS",
    },
    { label: "Omnia/Mini y HK-XS con Blumotion", 
      value: "HK_XSBLUEMOTION",
    }
  ]

  const getMechanizedTitle = (val) => {
    return result.map((item) => {
      if(item.value === val) {
        return item.label
      }
    })
  }

  const handleAccordionClick = (targetId) => {
    var temp = currentActive;
    if(temp.includes(targetId)) {
      var index = temp.indexOf(targetId)
      if(temp.length === 1) {
        temp = []
      } else {
        for (var i = index ; i < temp.length - 1 ; i ++) {
          temp[i] = temp[i+1]
        }
        temp.length = temp.length - 1;
      }
    } else {
      temp.push(targetId)
    }
    setCurrentActive([...temp])
  }


  let addProduct = {
    id: makeid(5),
    side: open,
    base: width,
    height,
    line,
    line_sku,
    filling_sku: nameColor,
    sash_sku: profile,
    hinge_positions: getHinges(),
    hinge_quantity: hinges === "noHinges" ? 0 : hinges.split("x").length,
    repetition: repeat,
    kind: type,
    black_film: blackFilm,
    mechanized: mechanized,
    height_furniture: height_furniture
  };

  console.log("addProduct", addProduct)
  console.log("profileColor", profile_color)

  return (
    <Row className="h-100" style={{ backgroundColor: '#F1F1F1' }}>
      <Sidebar
        onNext={() => addProductCart(addProduct, history)}
        onBack={() => {
          let path = window.location.pathname.split('/hinge')[0]
          return history.push(path + '/')
        }}
        disabledNext={false}
        width={'col-3'}
        isBottomCustom={true}
        bottomCustom={ 
          <div className='d-flex flex-column w-100 text-white align-items-center p-3' style={{ backgroundColor: 'white', marginTop: 'auto', gap: '14px', borderTop: '1px solid #d3d3d3' }}>
            <div 
              className='prevButton' 
              style={{ 
                backgroundColor: 'black', 
                padding: '18px', 
                width: '100%',
                minWidth: '240px',
                justifyContent: 'center'
              }} 
              onClick={() => addProductCart(addProduct, history)}
            > 
              <span>AGREGAR AL CARRO</span> 
            </div>
            <div 
              className='nextButton' 
              style={{ 
                color: 'black', 
                border: '1px solid #000', 
                padding: '18px', 
                width: '100%',
                minWidth: '240px', 
                justifyContent: 'center' 
              }} 
              onClick={() => {
                let path = window.location.pathname.split('/hinge')[0]
                return history.push(path + '/')
              }}
            > 
              <span>VOLVER</span> 
            </div>
          </div>
        }
      >
        <Accordion className="d-flex flex-column align-items-center " activeKey={currentActive} defaultActiveKey={["0"]} style={{ width: '90%' }}>
          <Accordion.Item className='w-100' eventKey='0'>
            <Accordion.Header className='w-100 mt-4 mg-lg-5' style={{ cursor: 'pointer', borderBottom: '1px solid #D3D3D3' }} onClick={() => { handleAccordionClick('0') }}>
              <div className="d-flex justify-content-between align-items-center w-100">
                <span className="font-weight-bold" style={{ color: 'black', fontSize: '16px' }}> 1. TIPO DE APERTURA </span>
                <span className='cambiar' onClick={() => history.push('/')}> Cambiar </span>
              </div>
            </Accordion.Header>
            <Accordion.Body className='w-100' style={{ padding: '0.6rem 0rem' }}>
              <span className='result-text'> { text[open] ? text[open] : open } </span>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className='w-100' eventKey='1'>
            <Accordion.Header className='w-100' style={{ cursor: 'pointer', borderBottom: '1px solid #D3D3D3' }} onClick={() => { handleAccordionClick('1') }}>
              <div className="d-flex justify-content-between align-items-center w-100">
                <span className="font-weight-bold" style={{ color: 'black', fontSize: '16px' }}> 2. COLECCIÓN Y COLOR </span>
                <span className='cambiar' onClick={() => {history.push(`${window.location.pathname.split('/width')[0]}/`)}}> Cambiar </span>
              </div>
            </Accordion.Header>
            <Accordion.Body className='w-100' style={{ padding: '0.6rem 0rem' }}>
              <div className='d-flex flex-column w-100'>
                <span className='result-title'> Medida (Ancho x alto) </span>
                <span className='result-text'> Ancho {width} mm x Alto {height} mm </span>

                <span className='result-title mt-2'>Color de perfil</span>
                <span className='result-text'> {profile_color} </span>

                <span className='result-title mt-2'> Tipo de perfil </span>
                <span className='result-text'> {sash.name} </span>

                <span className='result-title mt-2'> Color de relleno </span>
                <span className='result-text'> {filling.description} </span>
              </div>
            </Accordion.Body>            
          </Accordion.Item>
          <Accordion.Item className='w-100' eventKey='2'>
            <Accordion.Header className='w-100' style={{ cursor: 'pointer', borderBottom: '1px solid #D3D3D3' }} onClick={() => { handleAccordionClick('2') }}>
              <div className="d-flex justify-content-between align-items-center w-100">
                <span className="font-weight-bold" style={{ color: 'black', fontSize: '16px' }}> 3. TIPO DE MECANIZADO </span>
                <span className='cambiar' onClick={() => history.push(`${window.location.pathname.split('/hinge')[0]}/`)}> Cambiar </span>
              </div>
            </Accordion.Header>
            <Accordion.Body className='w-100' style={{ padding: '0.6rem 0rem' }}>
              <div className='d-flex flex-column w-100'>
                <span className='result-title'> Tipo de mecanizado </span>
                <span className='result-text'> {getMechanizedTitle(mechanized)} </span>
    
                <span className='result-title mt-2'> Posicion bisagra </span>
                <span className='result-text'> Con bisagra en posiciones: </span>
                <span className='result-text'> {getHingeString()} </span>

                <span className='result-title mt-2'> Cantidad de puertas </span>
                <span className='result-text'> {repeat} </span>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className='w-100' eventKey='3'>
            <Accordion.Header className='w-100' style={{ cursor: 'pointer', borderBottom: '1px solid #D3D3D3' }} onClick={() => { handleAccordionClick('3') }}>
              <div className="d-flex justify-content-between align-items-center w-100">
                <span className="font-weight-bold" style={{ color: 'black', fontSize: '16px' }}> 4. NO CONSIDERA </span>
                <span className='cambiar' onClick={() => history.push(`${window.location.pathname.split('/hinge')[0]}/`)}> Cambiar </span>
              </div>
            </Accordion.Header>
            <Accordion.Body className='w-100' style={{ padding: '0.6rem 0rem' }}>
              <div className='d-flex flex-column w-100'>
                <span className='result-title mt-1'> {open=="Avento" ? "Mecanismos" : ""} </span>
                <span className='result-title mt-1'> {open=="Slider" ? "" : "Bisagras"} </span>
                <span className='result-title mt-1'> {open=="Slider" ? "Carros" : ""} </span>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Sidebar>
      <div className="col-9 p-0 m-0 d-flex justify-content-center align-items-center" style={{ height: '100%', position: 'relative' }}>
        <div style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%', backgroundColor: 'black', opacity: '0.3', zIndex: '2' }}>
        </div>
        <img src="/coleccion_bg.jpg" style={{ width: '100%', height: '100%', position: 'absolute', top: '0px', left: '0px' }} />
      </div>
    </Row>
  )
}

const mapStateToProps = (state) => ({
  sash: state.sash,
  filling: state.filling,
  cart: state.cart,
  mechanized: state.mechanized.value,
  mechanizedTitle: state.mechanized.label,
  height_furniture: state.heightFurniture.height_furniture,
  hinges: state.hinges.hinge_position,
  profile_color: state.profileColor,
})

const mapDispatchToProps = (dispach) => ({
  addProductCart(product, history) {
    history.push(`/client/cart/buy`);
    toast.success("La puerta ha sido añadida al carrito de compras");
    dispach({
      type: "ADD_PRODUCT",
      product,
    });
  },
});

const wrapper = connect(mapStateToProps, mapDispatchToProps)
const component = wrapper(Resumen)

export default component