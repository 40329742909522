export default (value, sku) => {
  if(value === " undefined undefined"){
  }
  if (!!value && value != "") {
    value = value.toUpperCase();
    value = value[0].toUpperCase() + value.slice(1);
    return value;
  }
  return value;
};
