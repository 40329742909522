import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
// import { Link } from "react-router-dom";
import Input from "../../components/Input";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Route, Link } from "react-router-dom";
import BottomButtons from "../../components/bottomButtons";
import "./index.css";
import { Mechanized } from "./mechanized";

let maxHeightNormal = 2400;
let maxWidthNormal = 600;
let maxHeightGreengrocers = 900;
let maxWidthGreengrocers = 450;
const Size = ({
  open,
  history,
  changeDimensions,
  heightFurniture,
  mechanized,
  resetMechanized,
  ...props
}) => {
  const initialState = {
    door_width: 0,
    door_height: 0,
    furniture_height: 0,
    error_width: false,
    error_height: false,
    newWidthMin: 0,
    newWidthMax: 0,
    newHeightMin: 0,
    newHeightMax: 0,
    minWidth: 200,
    maxWidth: maxWidthNormal,
    minHeight: 300,
    maxHeight: maxHeightNormal,
  };
  const [sizeValues, setSizeValues] = useState(initialState);

  const [minWidth, setMinWidth] = useState("300");
  const [minHeight, setMinHeight] = useState("300");
  const [maxHeight, setmaxHeight] = useState("2400");
  const [maxWidth, setmaxWidth] = useState("600");
  const [minHeightFurniture] = useState("480");
  const [maxHeightFurniture] = useState("1040");

  const submitSize = () => {
    let { door_width, door_height } = sizeValues;
    let size_size = 0;
    let size_exception = 0;
    let errorMessage = "";
    if (open === "Left" || open === "Right" && mechanized.value !== 'SLIDER_RP50') {
      // The width (base) of the door can't be greater than height (alto)
      size_size = door_height;
      size_exception = door_width;
      errorMessage = "El ancho no puede exceder al alto";
    } else if(mechanized.value !== 'SLIDER_RP50'){
      // The height (alto) of the door can't be greater than width (base)
      size_size = door_width;
      size_exception = door_height;
      errorMessage = "El alto no puede exceder al ancho";
    }
    if (size_size >= size_exception) {
      history.push(
        `${window.location.pathname}/width/${door_width}/height/${door_height}/mechanized/${mechanized.value}`
      );
    } else {
      toast.warn(`${errorMessage} de la puerta`, {
        autoClose: 3600,
      });
    }
  };

  const changeWidth = () => {
    let { type } = props.match.params;
    let door_width = document.getElementById("door_width");
    let door_height = document.getElementById("door_height");
    if (open === "Top" || open === "Bottom" || open === "Avento") {
      door_width = document.getElementById("door_height");
      door_height = document.getElementById("door_width");
    }
    let inputValueWidth = parseInt(door_width.value);
    /* Base case, Small door. */
    let newMinwidth = 300;
    let newMaxwidth = 1350;
    if (type === "greengrocer") {
      newMaxwidth = 450;
    } else if (inputValueWidth < 300) {
      newMinwidth = 300;
    } else {
      door_width.max = 600;
      door_height.max = 2400;
    }
    door_width.min = newMinwidth;
    door_width.max = newMaxwidth;
    if (open === "Slider") {
      changeSizeSlider();
      getSizeLeyend();
    }
    if (open === "Avento") {
      changeSizeAvento();
      getSizeLeyend();
    }
  };

  const changeHeight = () => {
    let { type } = props.match.params;
    let door_width = document.getElementById("door_width");
    let door_height = document.getElementById("door_height");
    if (open === "Left" || open === "Right"){
      door_width = document.getElementById("door_height");
      door_height = document.getElementById("door_width");
    }
    let inputValueHeight = parseInt(door_height.value);
    /* Base case, Small door. */
    let newMinheight = 150;
    let newMaxheight = 600;
    if (type === "greengrocer") {
      newMaxheight = 900;
    } else if (inputValueHeight < 150) {
      newMinheight = 150;
    }
    door_height.min = newMinheight;
    door_height.max = newMaxheight;
    if (open === "Slider") {
      changeSizeSlider();
      getSizeLeyend();
    }
    if (open === "Avento") {
      changeSizeAvento();
      getSizeLeyend();
    }
  };
  // const setHeightFurniture = () => {
  //   if (open === "Avento") {
  //     return (
  //       // <div className='row heigth-furniture justify-content-center'>
  //       //   <div className="w-100">
  //       //     <div className='header-title w-100'>
  //       //       <span> Ingresa la altura del mueble</span>
  //       //     </div>
  //       //   </div>
  //       //   <div className="w-100">
  //       //     <Input
  //       //       id={"furniture_height"}
  //       //       title={"Altura del Mueble"}
  //       //       name={"furniture_height"}
  //       //       type={"number"}
  //       //       gloss={"Alto en mm"}
  //       //       changeSize={changeSize}
  //       //       min={minHeightFurniture}
  //       //       max={maxHeightFurniture}
  //       //       furniture_value={furniture_height}
  //       //     />
  //       //   </div>
  //       //   {/* <p style={{ color: "#55575B" }}>
  //       //     La altura mínima del mueble es de {minHeightFurniture} y la máxima de{" "}
  //       //     {maxHeightFurniture}.
  //       //   </p> */}
  //       // </div>
  //       <></>
  //     )

  //   }
  // }

  // handleGreenGrocer
  // 
  // Validate the size of the GreenGrocer door to show the filling
  // @notes  Show GreenGrocer filling if width + height >= 450 and  width + height <= 1350 
  // @return [bool] true if show GreenGrocer filling. false if not show GreenGrocer filling 
  const handleGreenGrocer = () => {
    let door_width = document.getElementById("door_width");
    let inputValueWidth = parseInt(door_width.value);
    let door_height = document.getElementById("door_height");
    let inputValueHeight = parseInt(door_height.value);
    if (
      inputValueWidth + inputValueHeight <= 1350 &&
      inputValueWidth + inputValueHeight >= 450
    ) {
      return props.changeGreengrocerOpt(true);
    }
    props.changeGreengrocerOpt(false); // if !900 450.
  };

  const changeSizeSlider = () => {
    let { type } = props.match.params;
    let door_width = document.getElementById("door_width");
    let door_height = document.getElementById("door_height");
    let inputValueWidth = parseInt(door_width.value);
    let inputValueHeight = parseInt(door_height.value);
    /* Base case, Small door. */

    door_width.min = 450;
    door_height.min = 450;
    door_height.max = 1100;
    door_width.max = 1500;
  };

  const changeSizeAvento = () => {
    let door_width = document.getElementById("door_width");
    let door_height = document.getElementById("door_height");

    door_width.min = 300;
    door_height.min = 270;
    door_height.max = 520;
    door_width.max = 1400;
  }
  
  const changeSize = () => {
    let { changeSizePreview } = props;
    let door_width = document.getElementById("door_width");
    let door_height = document.getElementById("door_height");
    let furniture_height = document.getElementById("furniture_height")
    let inputValueWidth = door_width ? parseInt(door_width.value) : NaN;
    let inputValueHeight = door_height ? parseInt(door_height.value) : NaN;
    let inputFurnitureHeight = furniture_height ? parseInt(furniture_height.value) : NaN;

    changeDimensions(inputValueWidth, inputValueHeight);
    heightFurniture(inputFurnitureHeight);
    changeWidth();
    changeHeight();
    

    let newWidthMin = parseInt(door_width.min);
    let newWidthMax = parseInt(door_width.max);
    let newHeightMin = parseInt(door_height.min);
    let newHeightMax = parseInt(door_height.max);

    const valueWidth = isNaN(inputValueWidth) ? 0 : inputValueWidth;
    const valueHeight = isNaN(inputValueHeight) ? 0 : inputValueHeight;    
    const valueFurnitureHeight = isNaN(inputFurnitureHeight) ? 0 : inputFurnitureHeight;    
    setSizeValues({
      ...sizeValues,
      door_width: valueWidth,
      door_height: valueHeight,
      furniture_height: valueFurnitureHeight,
      error:
        inputValueWidth < newWidthMin ||
        inputValueWidth > newWidthMax ||
        inputValueHeight < newHeightMin ||
        inputValueHeight > newHeightMax,
      newWidthMin,
      newWidthMax,
      newHeightMin,
      newHeightMax,
    });

    handleGreenGrocer();
  };

  const changeSizeGreengrocers = () => {
    let { type } = props.match.params;
    let changeMaxHeight =
      type === "greengrocer" ? maxHeightGreengrocers : maxHeightNormal;
    let changeMaxWidth =
      type === "greengrocer" ? maxWidthGreengrocers : maxWidthNormal;
    setSizeValues({
      ...sizeValues,
      maxWidth: changeMaxWidth,
      maxHeight: changeMaxHeight,
    });
  };

  useEffect(() => {
    let { resetFilling, resetSash, resetSize } = props;
    resetSash();
    resetSize();
    resetMechanized();
    heightFurniture();
    changeSizeGreengrocers();
    getSizeLeyend();
  }, [open]);

  
  // Sets the max Height depending on the type of door and the door width
  // @notes The values are inverted if the door has hinges on top or bottom
  useEffect(() => {
    if(["Top", "Bottom"].includes(open)) {
      if(sizeValues['door_width'] > 2100) { 
        setmaxHeight("500")
      }
      else {
        setmaxHeight("600")
      }
    }
    else if(["Left", "Right"].includes(open)) {
      if(sizeValues['door_width'] > 500) { 
        setmaxHeight("2100")
      }
      else {
        setmaxHeight("2400")
      }
    }
  }, [sizeValues['door_width']])

  // Sets the max Width depending on the type of door and the door height
  // @notes The values are inverted if the door has hinges on top or bottom
  useEffect(() => {
    if(["Top", "Bottom"].includes(open)) {
      if(sizeValues['door_height'] > 500) { 
        setmaxWidth("2100")
      }
      else {
        setmaxWidth("2400")
      }
    }
    else if(["Left", "Right"].includes(open)) {
      if(sizeValues['door_height'] > 2100) { 
        setmaxWidth("500")
      }
      else {
        setmaxWidth("600")
      }
    }
  }, [sizeValues['door_height']])


  const getSizeLeyend = () => {
    if (open === "Top" || open === "Bottom" ) {
      setMinWidth("150");
      setMinHeight("150");
      setmaxHeight("600");
      setmaxWidth("2400");
    }
    if (open === "Left" || open === "Right"){
      setMinWidth("150");
      setMinHeight("150");
      setmaxHeight("2400");
      setmaxWidth("600");
    }
    if (open === "Avento") {
      setMinWidth("300");
      setMinHeight("270");
      setmaxHeight("520");
      setmaxWidth("1400");
    }
    if (open === "Slider") {
      setmaxHeight("1100");
      setmaxWidth("1500");
      setMinWidth("450");
      setMinHeight("450");
    }
  };

  const {
    door_width,
    door_height,
    furniture_height,
    newWidthMin,
    newWidthMax,
    newHeightMin,
    newHeightMax,
    error,
  } = sizeValues;
  let { type } = props.match.params;
  return (
    <Container className='option-box p-0 mt-3'>
      <div className='step-two-options justify-content-center'>
        <div className="w-100" style={{marginBottom : '24px'}}>
          <Input
            id={"door_width"}
            title={"Ancho"}
            name={"door_width"}
            type={"number"}
            gloss={"Ancho en mm"}
            changeSize={changeSize}
            min={minWidth}
            max={maxWidth}
            door_value={door_width}
          />
          {/* <p style={{ color: "#55575B" }}>
            La medida mínima del ancho es de {minWidth} y la máxima de{" "}
            {maxWidth}.
          </p> */}
        </div>
        <div className="w-100">
          <Input
            id={"door_height"}
            title={"Alto"}
            name={"door_height"}
            type={"number"}
            gloss={"Alto en mm"}
            changeSize={changeSize}
            min={minHeight}
            max={maxHeight}
            door_value={door_height}
          />
          {/* <p style={{ color: "#55575B" }}>
            La medida mínima de la altura es de {minHeight} y la máxima de{" "}
            {maxHeight}.
          </p> */}
        </div>
      </div>
      {/* <Row className='justify-content-center'>
        <Col lg='12' xs='12' md='12'>
          <div className='header-title'>
            <h4 className=''>
              <div>
                <span>3</span>
              </div>
              <div>Selecciona el tipo de mecanizado
                <br></br>
                <small className='text-muted' >* Las bisagras no vienen incluidas</small>
              </div>
            </h4>
          </div>
        </Col>
        <Col lg='10' md='10' xs='12' className='mt-2 mt-lg-0 mb-2 mb-lg-0'>
          <Mechanized />
        </Col>
      </Row> */}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  size: state.size,
  open: state.open,
  mechanized: state.mechanized,
});

const mapDispatchToProps = (dispach) => ({
  changeSizePreview(door_width, door_height) {
    dispach({
      type: "CHANGE_SIZE",
      door_width,
      door_height,
    });
  },
  changeGreengrocerOpt(opt) {
    dispach({
      type: "CHANGE_GREENGROCER_OPT",
      opt,
    });
  },
  resetFilling() {
    dispach({
      type: "RESET_FILLING",
    });
  },
  resetSash() {
    dispach({
      type: "RESET_SASH",
    });
  },
  resetSize() {
    dispach({
      type: "RESET_SIZE",
    });
  },
  changeDimensions(width, height) {
    dispach({
      type: "CHANGE_DIMENSIONS",
      payload: {
        height,
        width,
      },
    });
  },
  resetMechanized() {
    dispach({
      type: "RESET_MECHANIZED",
    });
  },
  heightFurniture(inputFurnitureHeight){
    dispach({
      type: "HEIGHT_FURNITURE",
      height_furniture: inputFurnitureHeight
    });
  },
});

const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(Size);
 
export default component;
