import React from "react";
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@material-ui/core";

const SelectInput = ({
  title,
  onChange,
  value,
  disabled,
  options,
  defaultOptions,
  key,
}) => {
  //options like {value: hiddenValue, label:showValue }

  const renderOptions = (arr, type) => {
    return arr.map((item, index) => (
      <MenuItem key={`${key}-${index}-option-${type}`} value={item.value}>
        {item.label}
      </MenuItem>
    ));
  };
  return (
    <div className="d-flex flex-column col-12 align-items-start">
      <p className="m-0 mb-2"> {title} </p>
      <FormControl className="m-0 mb-2" variant='outlined' fullWidth margin='normal' key={key} error={value === ""}>
        <InputLabel
          variant='outlined'
          shrink
          id={`client-ship-address-label`}
          style={{
            backgroundColor: "white",
            paddingRight: "0.5rem",
            paddingLeft: "0.5rem",
          }}
        >
          {/* {title} */}
        </InputLabel>
        <Select
          key={`${key}-select-component`}
          id={`client-ship-address-options`}
          className='text-left'
          name={"options select"}
          value={value}
          disabled={disabled}
          required={true}
          onChange={(e) => onChange(e.target.value)}
        >
          {defaultOptions && renderOptions(defaultOptions, "default")}
          {options && renderOptions(options, "opts")}
        </Select>

      </FormControl>
    </div>
  );
};

export default SelectInput;