const sizeRestrictions = {
  STYLO: 3000,
  OMNIA: 2900,
  OFF: 2200,
  MEGA: 2900,
  MINI: 1900,
};

const hasColor = (stylo, omnia, off, mega, mini) => {
  return {
    STYLO: stylo,
    OMNIA: omnia,
    OFF: off,
    MEGA: mega,
    MINI: mini
  };
};

// Comentario Adicional para Perfiles hasColor hace enfasis a los 3 tipos de perfiles
// para autorizar que uno se muestre o no solo se debe pasar de false a true. 'Type Value'

export const colorRestriction = {
  BASIC: {
    INCOLORO: hasColor(true, true, false, true, true),
    HUMO: hasColor(true, true, false, true, true),
  },
  TREND: {
    HUMO: hasColor(true, true, false, true, true),
    PACIFIC: hasColor(true, true, false, false, true),
    ESPEJO: hasColor(true, true, true, true, true),
    SATEN: hasColor(true, true, false, true, true),
    BRONCE: hasColor(true, true, true, true, true),
    CAFE: hasColor(true, true, true, true, true), 
  },
  COLOR: {
    PACIFIC: hasColor(true, true, true, false, true),
    NEGRO: hasColor(true, true, true, true, true),
    SILVER: hasColor(true, true, true, false, true),
    CLASICO: hasColor(true, true, true, false, true),
    OPACO: hasColor(true, true, true, false, true),
    BEIGE: hasColor(true, true, true, false, true),
    BLANCO: hasColor(true, true, true, false, true),
    CAFE: hasColor(true, true, true, true, true),
  },
  VERDULERA: {
    TRANSPARENTE: hasColor(true, true, false, true, true),
  },
};


const getFillingColor = (fillingDescription) => {
  const fillingDescArray = fillingDescription?.split(/[\s-]+/);
  if (fillingDescArray?.length < 2) return null;
  if (fillingDescArray?.[0] === "ESPEJO") return fillingDescArray[0];
  if (fillingDescArray?.[1] === "SATEN") return fillingDescArray[1];
  if (fillingDescArray?.[1] === "HUMO") return fillingDescArray[1];
  if (fillingDescArray?.[2] === "EXTRA") return "BLANCO";
  return fillingDescArray?.[2];
};

const mechanizedRestriction = (mechanized, line, fillingDescription, description) => {
  const [,profile, ...rest] = description

  // if (mechanized === "35MM_HINGE" && profile === "MINI") { 
  //   return false;
  // }

  // if ( ["SLIDER_RP50", "35MM_HINGE"].includes(mechanized) && profile === "OMNIA"){
  //   return false;
  // }

  // if ( ["OMNIA_HINGE", "BLUEMOTION_HINGE"].includes(mechanized)) {
  //   if (["OMNIA","MINI"].includes(profile)) return true;

  //   return false;
  // }

  return colorRestriction[line][getFillingColor(fillingDescription)][
    profile
  ];
};

const isRenderSash = (line, fillingDescription, description, mechanized) => {
  if (line === "" && description.length < 3) {
    return false;
  }
  if (!colorRestriction[line])  { 
    return false;
  }
  if (!colorRestriction[line][getFillingColor(fillingDescription)]) {  
    return false;
  }

  return mechanizedRestriction(
    mechanized,
    line,
    fillingDescription,
    description
  );
};

export { sizeRestrictions, isRenderSash };
