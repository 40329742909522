import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import BottomButtons from "../../../components/bottomButtons";
import "./index.css";
import { formatPriceDots } from "../../../helpers/formatPrice";
import { useHistory } from "react-router-dom";
import {
  getProductPrices,
  findCommunePrice,
  findCommuneWeightPrice,
} from "../../../api/APIGet";
import HBTModal from "../../../components/Modal/HBTModal";

const ExtendBuy = ({
  personalData,
  cart,
  rut,
  deliveryInformation,
  ...props
}) => {
  const [showPrices, setShowPrices] = useState(true);
  const [disableButton, setDisableButton] = useState(true);
  const [packagingPrice, setPackagingPrice] = useState(0);
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [cartItemsPrice, setCartItemsPrice] = useState(0);
  const [subtotalPrice, setSubtotalPrice] = useState(null);
  const [iva, setiva] = useState(null);
  const [total, setTotal] = useState(null);
  const [quantityPackaging, setquantityPackaging] = useState(null);
  const [priceList, setPriceList] = useState("TIENDA");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setDeliveryPrice(props.deliveryPrice);
  }, [props.deliveryPrice]);

  useEffect(() => {
    if (personalData.price_list == "") {
      return;
    }
    setPriceList(personalData.price_list);
    setDisableButton(false);
  }, [personalData.price_list]);

  useEffect(() => {
    calculatePrices();
  }, [
    cart.array_price,
    priceList,
    deliveryPrice,
    deliveryInformation.kind,
    deliveryInformation.shipping_address,
    deliveryInformation.packaging_sku,
  ]);

  const calculatePrices = async () => {
    /* Get Packaging price */
    let subtotal = 0;
    let packagingSubTotal = 0;
    let packagePrice = 0;
    let priceAllProducts = cart.array_price.reduce(
      (a, b) => +a + +b.publicPriceTotal,
      0
    );
    let maxWeight = cart.array_weight.reduce((a, b) => +a + +b, 0);
    setCartItemsPrice(priceAllProducts);

    const numRepetitions = cart.product.reduce((acumRepetitions, door) => {
      return acumRepetitions + +door.repetition;
    }, 0);
    setquantityPackaging(numRepetitions);
    if (deliveryInformation.packaging_sku === "0") {
      setPackagingPrice(0);
      packagePrice = 0;
      packagingSubTotal = 0;
    } else {
      const packagingPrices = await getProductPrices(
        deliveryInformation.packaging_sku
      );
      let price = packagingPrices.find((price) => price.name === priceList);
      if (price) {
        setPackagingPrice(+price.value);
        packagePrice = +price.value;
        packagingSubTotal = numRepetitions * +packagePrice;
      } else {
        setPackagingPrice(0);
        packagePrice = 0;
        packagingSubTotal = 0;
      }
    }
    if (deliveryInformation.kind === "ship") {
      /* Get delivery package */
      let commune = deliveryInformation.shipping_address.commune;
      if (commune === "" || personalData.price_list == "") {
        props.setDeliveryPrice(0);
      } else {
        let deliveryPrice = await findCommuneWeightPrice(commune, maxWeight);
        if (deliveryPrice.data.attributes) {
          props.setDeliveryPrice(+deliveryPrice.data.attributes.net_unit_price);
        }
      }
    }
    subtotal = priceAllProducts + packagingSubTotal + props.deliveryPrice;
    setSubtotalPrice(subtotal);
    // IMPORTANT! set sum of prices before calculate iva!
    setiva(Math.round(subtotal * 0.19));
    setTotal(Math.round(subtotal * 1.19));
  };

  const renderDeliveryPrices = () => {
    if (deliveryInformation.kind === "store") {
      return props.deliveryPrice
    }
    if (deliveryInformation.kind === "logistic-train") {
      return 0;
    }
    if (showPrices && deliveryPrice) {
      return formatPriceDots(+props.deliveryPrice);
    }
    return 0;
  };

  return (
    <Container>
      <HBTModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
      <Row>
        <Col className="col-12 col-lg-12">
          <table className="table table-sm table-padding">
            <tbody className="text-right text-primary">
              <tr>
                <td
                  className="col-5"
                  style={{
                    width: "100%",
                    color: "#212529",
                    fontWeight: "300",
                    paddingRight: "1rem",
                  }}>
                  Embalaje
                </td>
                <td className="col-7">
                  {quantityPackaging} x $
                  {showPrices
                    ? packagingPrice && formatPriceDots(+packagingPrice)
                    : ""}
                </td>
              </tr>
              <tr>
                <td
                  className="col-5"
                  style={{
                    width: "100%",
                    color: "#212529",
                    fontWeight: "300",
                    paddingRight: "1rem",
                  }}>
                  Despacho
                </td>
                <td className="col-7">
                  ${renderDeliveryPrices()}
                </td>
              </tr>
              <tr>
                <td
                  className="col-5"
                  style={{
                    width: "100%",
                    color: "#212529",
                    fontWeight: "300",
                    paddingRight: "1rem",
                  }}>
                  SubTotal
                </td>
                <td className="col-7">
                  ${showPrices ? formatPriceDots(subtotalPrice) : ""}
                </td>
              </tr>
              <tr>
                <td
                  className="col-5"
                  style={{
                    width: "auto",
                    color: "#212529",
                    fontWeight: "300",
                    paddingRight: "1rem",
                  }}>
                  +IVA
                </td>
                <td className="col-7">
                  ${showPrices ? formatPriceDots(iva) : ""}
                </td>
              </tr>
              <tr>
                <td
                  className="col-5 font-weight-bold"
                  style={{
                    width: "auto",
                    color: "#212529",
                    fontWeight: "300",
                    paddingRight: "1rem",
                  }}>
                  Total
                </td>
                <td className="col-7 font-weight-bold">
                  ${showPrices ? formatPriceDots(total) : ""}
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
      <Row>
        <BottomButtons

          onBack={() => {
            history.push("/");
          }}
          buttonClassName="col-12"
          typeNext="submit"
          lastLabel="VOLVER"
          nextLabel="COTIZAR"
          disabledNext={props.errors}
          isTermsAndConditions={true}
          setIsModalOpen={setIsModalOpen}
        />
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  cart: state.cart,
  personalData: state.statePersonal.personalData,
  rut: state.statePersonal.rut,
  deliveryInformation: state.deliveryInformation,
});

const mapDispatchToProps = (dispatch) => ({});

const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(ExtendBuy);

export default component;
