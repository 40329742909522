import { global } from "./data";

export const createWorkOrder = (products, dataClient, deliveryInformation) => {
  let data = {
    type: "created_work_order",
    id: 0,
    attributes: {
      user: {
        personal_data: dataClient,
      },
      cart: {
        products: products,
        delivery_information: deliveryInformation,
      },
    },
  };
  let url = `${global.api.url}/door_work_orders`;
  return fetch(url, {
    method: "POST",
    headers: global.api.header,
    body: JSON.stringify({ data }),
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((error) => console.error("Error json:", error));
};
