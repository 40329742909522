import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Hinge from "./Hinge";
import { connect } from "react-redux";
import "./index.scss";

const QuestionUncover = ({
  history,
  changeStateHinge,
  location,
  add_hinge_boolean,
  preview,
  ...props
}) => {
  const [uncover, setUncover] = useState(true);

  useEffect(() => {
    // changeStateHinge(true);
    toNextStep();
  }, []);

  const toNextStep = () => {
    uncover
      ? changeStateHinge(uncover)
      : history.push(`${location.pathname}hinge/0|A`);
  };

  return (
    <Hinge {...props} preview={preview} />
  );
};

const mapStateToProps = state => ({
  add_hinge_boolean: state.hinges.add_hinge_boolean
});

const mapDispatchToProps = dispach => ({
  changeStateHinge(add_hinge_boolean) {
    dispach({
      type: "CHANGE_ADD_HINGE",
      add_hinge_boolean
    });
  }
});
const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(QuestionUncover);

export default component;
