import { global } from "./data";
import axios from "axios";
export const getFillingSection = (kind) => {
  let url = `${global.api_pim.url}/materials/fillers/${kind}?category=FRENTES Y PUERTAS PARA MUEBLES`;
  return fetch(url, {
    method: "GET",
    mode: "cors",
    headers: global.api.header,
  })
    .then((response) => response.json())
    .then((response) => {
      return response.data;
    })
    .catch((error) => console.error("Error json:", error));
};

export const getAllProfile = () => {
  let url = `${global.api_pim.url}/materials/profiles`;
  return fetch(url, {
    method: "GET",
    mode: "cors",
    headers: global.api.header,
  })
    .then((response) => response.json())
    .then((response) => {
      return response.data;
    })
    .catch((error) => console.error("Error json:", error));
};

export const getStores = () => {
  let url = `${global.api_pim.url}/stores/by_application?query=doors`
  return fetch(url, {
    method: "GET",
    headers: global.api.header,
  })
    .then((response) => response.json())
    .then((response) => {
      return response.data;
    })
    .catch((error) => console.error("Error json:", error));
}

export const getMaterial = (sku) => {
  let url = `${global.api_pim.url}/products/${sku}`;
  return fetch(url, {
    method: "GET",
    headers: global.api.header,
  })
    .then((response) => response.json())
    .then((response) => {
      return response.data;
    })
    .catch((error) => console.error("Error json:", error));
};

export const getMaterialAsync = (sku) => {
  let url = `${global.api_pim.url}/products/${sku}`;
  return axios.get(url, { headers: global.api.header });
};

export const findClient = (ref) => {
  let url = `${global.api.url}/clients/find?ref=${ref}`;
  return fetch(url, {
    method: "GET",
    headers: global.api.header,
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((error) => console.error("Error json:", error));
};

export const findCommunePrice = (commune, priceList) => {
  let url = `${global.api.url}/clients/find_price_commune`;
  return axios
    .get(url, {
      headers: global.api.header,
      params: {
        commune,
        price_list: priceList,
      },
    })
    .then((price) => {
      return price.data;
    })
    .catch((e) => {
      console.error("Error fetching delivery prices: ", e);
    });
};

export const findCommuneWeightPrice = (commune, max_weight) => {
  let url = `${global.api_pim.url}/boards/flexline_quotes/volumetric_delivery_price`;
  return axios
    .get(url, {
      headers: global.api.header,
      params: {
        commune_name: commune,
        max_weight: max_weight,
      },
    })
    .then((price) => {
      return price.data;
    })
    .catch((e) => {
      console.log("Error fetching delivery prices: ", e);
    });
};

export const allCommunes = () => {
  let url = `${global.api.url}/clients/get_all_communes`;
  return fetch(url, {
    method: "GET",
    headers: global.api.header,
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((error) => console.error("Error json:", error));
};

// Filtered Communes
// 
// Get the enabled communes from the api
export const filteredCommunes = () => {
  let url = `${global.api.url}/clients/get_enabled_communes`;
  return fetch(url, {
    method: "GET",
    headers: global.api.header,
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((error) => console.error("Error json:", error));
};

export const getProductPrices = (sku) => {
  return axios
    .get(`${global.api_pim.url}/products/${sku}/prices`, {
      headers: global.api_pim.header,
    })
    .then((response) => response.data.data.attributes.prices)
    .catch((error) => console.error("Error json:", error));
};

export const getProductWeight = (sku) => {
  return axios
    .get(`${global.api_pim.url}/products/${sku}`, {
      headers: global.api_pim.header,
    })
    .then((response) => response.data.data.attributes.max_logistic_weight)
    .catch((error) => console.error("Error json:", error));
};
