import React from "react";
import { render } from "react-dom";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
// import App from './inicial_project/App';
import Index from "./client/Index";
// import Supervisor from './supervisor/Index';
// import Cutter from './cutter/Index';
import * as serviceWorker from "./serviceWorker";
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Route } from "react-router-dom";
import Home from "./client/home";
// import rootReducer from './reducers/'

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ToastContainer />
        <Route
          exact
          path='/'
          component={props => {
            props.history.push(`/client/type/normal/open`);
            return null;
          }}
        />
        <Route
          exact
          path='/client'
          component={props => {
            props.history.push(`/client/type/normal/open`);
            return null;
          }}
        />
        <Route
          exact
          path='/client/type/'
          component={props => {
            props.history.push(`/client/type/normal/open`);
            return null;
          }}
        />
        <Route
          exact
          path='/client/type/normal/'
          component={props => {
            props.history.push(`/client/type/normal/open`);
            return null;
          }}
        />
        <Route path='/client' component={Index} />
        {/* <Route path='/supervisor' component={Supervisor} />
        <Route path='/cutter' component={Cutter} /> */}
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
