import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
// import results from './reducers/results';
import product from "./reducers/productReducer";
import hinges from "./reducers/hingeDimentionReducer";
import buySelected from "./reducers/buySelectedReducer";
import stateSelectCommune from "./reducers/stateSelectCommuneReducer";
import open from "./reducers/openSelectedReducer";
import size from "./reducers/sizeSelectedReducer";
import filling from "./reducers/fillingReducer";
import sash from "./reducers/sashReducer";
import statePersonal from "./reducers/personalDataReducer";
import listDoorsItems from "./reducers/listDoorsItemsReducer";
import greengrocerReducer from "./reducers/greengrocerReducer";
import deliveryReducer from "./reducers/deliveryReducer";
import mechanizedReducer from "./reducers/mechanizedReducer";
import dimensionsReducer from "./reducers/dimensionsReducer";
import heightFurniture from "./reducers/heightFurnitureReducer";
import countReducer from './reducers/countReducer';
import profileFetchFillingReducer from "./reducers/profileFetchFillingReducer"
import profileColor from "./reducers/colorProfileReducer"

const persistConfig = {
  key: 'root',
  storage,
}
const reducer = combineReducers({
  // results,
  cart: product,
  hinges,
  buySelected,
  stateSelectCommune,
  open,
  size,
  filling,
  sash,
  profileColor,
  profileFetchFillingReducer,
  statePersonal,
  listDoorsItems,
  heightFurniture: heightFurniture,
  greengrocerOption: greengrocerReducer,
  deliveryInformation: deliveryReducer,
  mechanized: mechanizedReducer,
  dimensions: dimensionsReducer,
  counter: countReducer,
});
// const store = createStore(
//   reducer
// );

// const store = createStore(
//  reducer,
//  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );
const persistedReducer = persistReducer(persistConfig, reducer)
let store = createStore(persistedReducer)
let persistor = persistStore(store)

export { store, persistor }
