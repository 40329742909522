import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Finished.scss";
import logo from "../../img/logo.png";
import hbt_boards from "../../img/hbt_boards.png";
import hbt_closets from "../../img/hbt_closets.png";

const Finished = (props) => {
  let { correlative } = props.location.state;
  return (
    <Container className='finished'>
      <Row className='text-center mt-5'>
        <Col xs='12' lg='12'>
          <h6>
            Tu cotización ha sido enviada en forma exitosa a tu correo y a todos nuestros puntos de venta con el siguiente número{" "}
            {correlative}
          </h6>
        </Col>
      </Row>

      <Row className='text-center mt-3'>
        <Col xs='12' lg='12'>
          <h6>
            Para hacer efectivo tu compra y conocer las formas de pago por favor
            contactar a nuestro call center +562 2017650
          </h6>
        </Col>
      </Row>
      <Row className='text-center mt-3'>
        <Col xs='12' lg='12'>
          <h6>Te invitamos a conocer nuestros servicios, donde encontrás todo para tu nuevo proyecto!!</h6>
        </Col>
      </Row>
      <Row style={{ justifyContent: "space-between" }} className='mt-5'>
        <Col className='btn btn-block'>
          <a
            className='btn btn-primary-checkout'
            href='https://puertas.hbtsoluciones.cl/'
            target='_blank'
          >
            Seguir comprando
          </a>
        </Col>
      </Row>
      <Row style={{ justifyContent: "space-between" }} className='mt-5'>
        <Col className='solution-link btn btn-block' md='6' xs='12'>
          <img src={hbt_boards} />
          <a
            className='hbt-service-button'
            href='https://tableros.hbtsoluciones.cl/'
            target='_blank'
          >
            Servicio de dimensionado y enchape
          </a>
        </Col>
        <Col className='solution-link btn btn-block' md='6' xs='12'>
          <img src={hbt_closets} className='image-background'/>
          <a
            className='hbt-service-button'
            href='https://separadores.hbtsoluciones.cl/'
            target='_blank'
          >
            Separadores y puertas de clósets
          </a>
        </Col>
      </Row>
      <Row className='footer-options'>
        <Col>
          <h5>Santiago</h5>
        </Col>
        <Col>
          <h5>Temuco</h5>
        </Col>
        <Col>
          <h5>Puerto Montt</h5>
        </Col>
      </Row>
    </Container>
  );
};

export default Finished;
