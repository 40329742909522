import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import './refill.scss';
import { Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core';

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    border: '1px solid #F1F1F1',
    borderRadius: '4px',
    padding: '10px',
    fontSize: '14px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
}));

const Refill = ({ changeProfileColor, ...props }) => {
  const classes = useStylesBootstrap();

  const colors = [
    {
      sku: 'negro',
      description: 'Negro',
      color: '#000000',
    },
    {
      sku: 'silver',
      description: 'Silver',
      color: '#D5D5D3',
    },
    {
      sku: "champagne",
      description: "Champagne",
      color: "#BAB29B"
    }
  ];

  const [activeColor, setActiveColor] = useState('');

  return (
    <Container className="Refill">
      
      {colors.map((item, key) => (
        <div
          className={`main-warpper`}
          key={key}
          onClick={() => {
            props.setColorSelected(true);
            setActiveColor(item.color);
            props.setActiveSku(item.sku);
            props.setGlobalProfile("");
            changeProfileColor(item.sku);
          }}
        >
          <div
            className={`${
              item.color === activeColor ? 'selected-filling' : ''
            }`}
            style={{
              width: '56px',
              height: '56px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin : 'auto',
              cursor: 'pointer'
            }}
          >
            <Tooltip
              arrow
              classes={classes}
              title={item.description}
              placement="right"
            >
              <div
                style={{
                  backgroundColor: `${item.color}`,
                  width: '48px',
                  height: '48px',
                }}
              />
            </Tooltip>
          </div>
        </div>
      ))}
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  changeProfileColor(profile_color) {
    dispatch({
      type: 'CHANGE_PROFILE_COLOR',
      profile_color,
    });
  },
});

const wrapper = connect(null, mapDispatchToProps);
const component = wrapper(Refill);

export default component;
