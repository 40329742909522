import React, { useEffect, useState } from "react";
import { getFillingSection } from "../../api/APIGet";
// import colors from '../../api/colors.json'
import AllColor from "./AllColor";

const FillingSection = ({ filler}) => {
  /*const [collection, setcollection] = useState([]);

  useEffect(() => {
    getFillingSection(value.kind).then((response) => {
      let response_filter = [];
      response_filter = response.filter((d) => d.attributes.photos.length > 0);
      if (response_filter.length <= 0) {
        response_filter = response.filter(
          (d) => d.attributes.public_price !== "NO TIENE"
        );
      }
      let filteredCollection = response_filter;
      if (!props.greengrocerOption) {
        filteredCollection = filteredCollection.filter((fill) => {
          return fill.attributes.sku !== "ALPUEPLAC12518500HBT";
        });
      }
      filteredCollection = filteredCollection.filter((fill) => {
        return fill.attributes.sku !== "RERELADIFACRCRJ5GHBT";
      });
      if (props.height > 2100 || props.width > 2100) {
        filteredCollection = filteredCollection.filter((fill) => {
          return fill.attributes.sku !== "RERELCRICOGLHUDDJSPE";
        });
      }
      else{
        filteredCollection = filteredCollection.filter((fill) => {
          return fill.attributes.sku !== "RERELCRIFAGLREZ6MOME";
        });
      }

      filteredCollection = filteredCollection.map((value) => {
        let url = "";
        let photo_section = value.attributes.photos.filter(
          (p) => p.usage === "FotoTecnica"
        );
        photo_section.forEach((value) => (url = value.url));
        let description = value.attributes.description;
        return {
          sku: value.id,
          url: url,
          available_stock: value.attributes.available_stock,
          available_stock_message: value.attributes.available_stock_message,
          description:
            value.attributes.sku !== "ALPUEPLAC12518500HBT"
              ? description
              : `${description} *Puerta verdulero`,
          subfamily: value.attributes.subfamily,
        };
      });

      // Here removes the specific SKUS for XLDoor when HBT has no stock
      if(checkXLDoor()){
        filteredCollection = filteredCollection.filter((fill) => {
          return fill['sku'] !== "ALPUECR4M360213BRHBT";
        });
      }

      setcollection(filteredCollection);
    });
  }, []);

  const checkXLDoor = () => {
    return (props['height'] > 2100 || props['width'] > 2100)
  }*/
  return (
    <AllColor
      filler={filler}
    />
  );
};
export default FillingSection;
