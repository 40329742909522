const defaultState = {
  kind: "store",
  packaging_sku: "ALPUEEMSOLFAB0031HBT",
  store_id: "",
  store_delivery_price: "",
  shipping_address: {
    address: "",
    commune: "",
    phone: "",
    email: "",
  },
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "SELECT_STORE":
      return {
        ...state,
        store_id: payload.id,
        store_delivery_price: payload.price,
      }; 
    case "CHANGE_KIND_SHIPPING":
      return {
        ...state,
        kind: payload.kind,
        packaging_sku: "ALPUEEMSOLFAB0031HBT",
      };
    case "CHANGE_PACKAGING_SKU": {
      return {
        ...state,
        packaging_sku: payload.packaging_sku,
      };
    }
    case "CHANGE_SHIPPING_ADDRESS_VALUE":
      return {
        ...state,
        shipping_address: {
          ...state.shipping_address,
          ...payload.shipping_address,
        },
      };
    case "CHANGE_SHIPPING_ADDRESS":
      return {
        ...state,
        shipping_address: payload.shipping_address,
      };
    case "RESET_SHIPPING_ADDRESS":
      return { ...state, shipping_address: defaultState.shipping_address };
    default:
      return state;
  }
}

export default reducer;
