// import items from '../../data/items';

// hinge position where add all mposition of hinges
// add hinge where is boolean | true = add hinge to door and false = the door don't have uncover
const defaultState = {
  hinge_position: [],
  add_hinge_boolean: true,
};

function reducer(
  state = defaultState,
  { type, hinge, oldHinge, add_hinge_boolean }
) {
  let hinges = {};
  switch (type) {
    case "CHANGE_ADD_HINGE": {
      hinges = {
        ...state,
        add_hinge_boolean,
      };
      break;
    }
    case "ADD_HINGE": {
      hinges = {
        ...state,
        hinge_position: state.hinge_position.concat(hinge),
      };
      break;
    }
    case "DELETE_HINGE": {
      hinges = {
        ...state,
        hinge_position: state.hinge_position.filter(
          (p) => p.order !== hinge.order
        ),
      };
      break;
    }
    case "CHANGE_VALUE_HINGE": {
      hinges = state.hinge_position.filter((p) => p.order !== oldHinge.order);
      hinges = {
        ...state,
        hinge_position: hinges.concat(hinge),
      };
      break;
    }
    case "RESET_STATE": {
      hinges = {
        ...state,
        hinge_position: [],
      };
      break;
    }
    default:
      hinges = state;
      break;
  }
  return hinges;
}

export default reducer;
