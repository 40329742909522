const defaultState = 1;

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "CHANGE_REPEAT": {
      return {
        ...state,
        ...payload.count,
      };
    }
    case "RESET_REPEAT": {
      return defaultState;
    }
    default:
      return state;
  }
}

export default reducer;
