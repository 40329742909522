import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./index.css";
import { connect } from "react-redux";
import { getOptions, getInitialOption } from "./options";
const Mechanized = ({
  mechanized,
  setMechanized,
  dimensions,
  open,
  resetMechanized,
  ...props
}) => {
  const [mechanizedSelected, setMechanizedSelected] = useState(mechanized);
  const [mechanizedOptions, setmechanizedOptions] = useState([]);
  const changeMechanized = (mechanized) => {
    setMechanizedSelected(mechanized)
    setMechanized(mechanized)
    validateMechanized(mechanized)
  };

  const validateMechanized = (mechanized) =>{
    const submitButton = document.querySelector('.btn-next')
    const mechanizedValue = mechanized.value.split('_')[0]
    
    submitButton.disabled = validateSizesMechanize(mechanizedValue)
  }

  const validateSizesMechanize = (mechanized) =>{
   
    let width = dimensions.width
    let height = dimensions.height
    let minimum = 150;
    if (open === "Top" || open === "Bottom" || open === "Avento") {
      width = dimensions.height
      height = dimensions.width
    }
    switch (mechanized){
      case 'OMNIA':
        return height > 2400 || width > 500 || (height || width) < minimum;
      case 'BLUEMOTION':
        return height > 2400 || width > 500 || (height || width) < minimum;
      default:
        return height > 2400 || width > 600 || (height || width) < minimum;
    }
  }

  useEffect(() => {
    const totalSize = parseInt(dimensions.width) + parseInt(dimensions.height);
    const newOptions = getOptions(open, totalSize, dimensions.height, dimensions.width);
    setmechanizedOptions([...newOptions]);
    if (
      parseInt(dimensions.height) <= 2400 &&
      totalSize > 2600 &&
      mechanizedSelected &&
      mechanizedSelected.value === "SLIDER_RP50"
    ) {
      changeMechanized(null);
      resetMechanized();
    }
  }, [dimensions, open]);

  const Option = ({ mechanized }) => {
    return (
      <Col
        xs='6'
        lg='3'
        className='mt-1 mb-1 mt-lg-0'
        key={`opt-${mechanized.value}`}
      >
        <Button
          className='mechanized-option'
          variant={
            mechanizedSelected && mechanizedSelected.value === mechanized.value
              ? "primary"
              : "outline-primary"
          }
          onClick={() => changeMechanized(mechanized)}
          disabled={mechanized.disabled}
          size='sm'
        >
          <span>{mechanized.label}</span>
        </Button>
      </Col>
    );
  };
  return (
    <Row>
      {mechanizedOptions.map((mechanized, index) => (
        <Option mechanized={mechanized} />
      ))}
    </Row>
  );
};
const mapStateToProps = (state) => ({
  mechanized: state.mechanized,
  dimensions: state.dimensions,
  open: state.open,
});

const mapDispatchToProps = (dispatch) => ({
  setMechanized(mechanized) {
    dispatch({
      type: "CHANGE_MECHANIZED",
      payload: {
        mechanized,
      },
    });
  },
  resetMechanized() {
    dispatch({
      type: "RESET_MECHANIZED",
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Mechanized);
