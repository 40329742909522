import React, { useEffect, useState } from "react";
import "./DoorToOpen.scss";
import { connect } from "react-redux";
import doorOpened from "../../img/doorOpened.png";
import doorClosed from "../../img/doorClosed.png";
import aventoOpened from "../../img/avento_opened.png";
import aventoClosed from "../../img/avento_closed.png";
import sliderOpened from "../../img/slider_opened.png";
import sliderClosed from "../../img/slider_closed.png";

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const DoorToOpen = ({ id, selectSide, onlyPreview, open, name }) => {
  const [image, setImage] = useState(doorClosed);
  const openSelected = () => {
    if (!onlyPreview) {
      if (id === "Avento" || id === "Slider") {
      }
      selectSide(id);
    }
  };

  const getOpenImage = () => {
    if (id === "Avento")
      return {
        open: aventoOpened,
        close: aventoClosed,
      };
    if (id === "Slider")
      return {
        open: sliderOpened,
        close: sliderClosed,
      };
    return { open: doorOpened, close: doorClosed };
  };
  const [images] = useState(getOpenImage());
  useEffect(() => {
    if (open === id) {
      setImage(images.open);
    } else {
      setImage(images.close);
    }
    if (onlyPreview) {
      selectSide(id);
    }
  }, [open]);

  const getRotation = () => {
    switch (id) {
      case "Right":
        return 180;
      case "Bottom":
        return 270;
      case "Left":
        return 0;
      case "Top":
        return 90;
      default:
        return 0;
    }
  };

  return (
    <div id={id} onClick={openSelected} className='direction-box' style={{ cursor: 'pointer' }}>
      <div className="d-flex flex-row align-items-center" style={{ gap: '15px' }}>
        {
          open === id ?
            <CheckCircleIcon fontSize="small" style={{marginLeft: "2px"}} />
            : 
            <div style={{ width: '20px', height: '20px', marginLeft: "2px" }}></div>
        }
        <img
          id={`img-${id}-id`}
          className={`col-12 p-0 arrow-style`}
          src={image}
          alt={`door ${id}`}
          style={{ transform: `rotate(${getRotation()}deg)`, marginLeft: "15px" }}
        />
      </div>
      {
        open === id ?
            <b>{name}</b>
          : <small>{name}</small>

      }
    </div>
  );
};
const mapStateToProps = (state) => ({
  open: state.open,
});

const mapDispatchToProps = (dispach) => ({
  selectSide(side) {
    dispach({
      type: "CHANGE_SIDE",
      side,
    });
  },
});

const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(DoorToOpen);

export default component;
