import React from "react";
import { connect } from "react-redux";
import { formatDescription } from "../../helpers";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { colorRestriction } from "../../api/restrictionSash";
import { getFillingSection } from "../../api/APIGet";

const BorderProfile = ({
  fromAdmin,
  description,
  color,
  id,
  sku,
  changeProfilePreview,
  previewPhoto,
  sku_selected,
  hoverPhoto,
  profileType,
  setFillingResult,
  fillingFetchedData,
  setGlobalProfile,
  globalProfile,
  setSelectedProfileSku,
  setRelleno,
  ...props
}) => {

    /*function filterParentKeys(criteria) {
      const parentKeys = new Set();
      const subKeys = new Set();

      for (const parentKey in colorRestriction) {
        for (const childKey in colorRestriction[parentKey]) {
          if (colorRestriction[parentKey][childKey][criteria]) {
            parentKeys.add(parentKey);
            subKeys.add(childKey);
          }
        }
      }

       // Create an array of promises for fetching data for each parent key
  const promises = Array.from(parentKeys).map(async (element) => {
    const response = await getFillingSection(element);
    return { fillers: response };
  });

  // Use Promise.all to wait for all requests to complete
  Promise.all(promises)
    .then((results) => {
      // Results will contain an array of objects with kind and fillers
      setFillers(results)
      
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
    }
  */

  return (
    <div
      className={`profile-container col-12 p-0 ${description}`}
      onClick={() => {setGlobalProfile(profileType)
        setSelectedProfileSku(sku)
        setRelleno("")
        changeProfilePreview(hoverPhoto, description, sku, previewPhoto)
      }}
    >
      {
        globalProfile === profileType ? 
          <CheckCircleIcon fontSize="small" />
          : 
          <div style={{ minWidth: '20px', minHeight: '20px' }}></div>
      }
      <div id={props.id}  className='hover'>
        <img className='hover__no-hover' src={color} />
        <img className='hover__hover' src={hoverPhoto} />
      </div>
      <b>{formatDescription(description)}</b>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sku_selected: state.sash.sku,
});

const mapDispatchToProps = (dispach) => ({
  changeProfilePreview(url, name, sku, previewPhoto) {
    dispach({
      type: "CHANGE_SASH",
      url,
      name,
      sku,
      previewPhoto,
    });
  },
  
});

const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(BorderProfile);

export default component;
