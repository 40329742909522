import React, { Component } from "react";
import BorderProfile from "../profile/BorderProfile";
import { getMaterial } from "../../api/APIGet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { formatDescription } from "../../helpers";

class BorderSelected extends Component {
  constructor() {
    super();
    this.state = {
      url: "",
      sku: "",
    };
  }

  componentDidMount() {
    let { changeProfilePreview, match } = this.props;
    let { profile } = match.params;
    getMaterial(profile).then((response) => {
      let photo_section = response.attributes.photos.filter(
        (p) => p.usage === "FotoTecnica"
      );
      let url = "";
      photo_section.forEach((value) => (url = value.url));
      changeProfilePreview(
        photo_section[0].url,
        "",
        profile,
        photo_section[1].url
      );
      let gloss = response.attributes.gloss.split(/[\s-]+/);
      gloss = `${gloss[0]} ${gloss[1]} ${gloss[2]}`;
      this.setState({
        url,
        sku: profile,
        gloss,
      });
    });
  }

  render() {
    let { url, sku, gloss } = this.state;
    let {
      open,
      type,
      width,
      height,
      line,
      line_sku,
      nameColor,
      mechanized
    } = this.props.match.params;
    return (
      <Link
        to={`/client/type/${type}/open/${open}/width/${width}/height/${height}/mechanized/${mechanized}/line/${line}/line_sku/${line_sku}/nameColor/${nameColor}`}
      >
        <div className='text-center profile-container-bread'>
          <img src={url} style={{ width: "100%", maxWidth: "88px" }} />
          <span>{formatDescription(gloss)}</span>
        </div>
      </Link>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispach) => ({
  changeProfilePreview(url, name, sku, previewPhoto) {
    dispach({
      type: "CHANGE_SASH",
      url,
      name,
      sku,
      previewPhoto,
    });
  },
});

const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(BorderSelected);

export default component;
