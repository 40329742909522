import { type as findCartdResults } from "../actions/AddProductResults";
// import items from '../../data/items';

let jsonProducts = JSON.parse(localStorage.getItem("cart") || "[]").product;

const defaultState = {
  product: jsonProducts === undefined ? [] : jsonProducts,
  array_price: [],
  array_weight: [],
};

function reducer(state = defaultState, { type, product, price, id, prices, weights }) {
  let cart = {};
  let array_price = [];
  let array_weight = [];
  switch (type) {
    case findCartdResults: {
      cart = {
        ...state,
        product: state.product.concat(product),
      };
      break;
    }
    case "DELETE_PRODUCT": {
      array_price = state.array_price;
      array_weight = state.array_weight
      let price_to_delete = array_price.find((p) => p.door_id == id)
      let weight_to_delete = array_weight.find((p) => p.door_id == id)
      const index_weight_to_delete = array_weight.indexOf(weight_to_delete);
      const index_value_to_delete = array_price.indexOf(price_to_delete);
      if (index_value_to_delete > -1) {
        array_price.splice(index_value_to_delete, 1);
      }
      if (index_weight_to_delete > -1) {
        array_weight.splice(index_weight_to_delete, 1);
      }
      cart = {
        ...state,
        product: state.product.filter((p) => p.id !== id),
        array_price,
        array_weight
      };
      break;
    }
    case "RESET_PRODUCT": {
      cart = {
        ...state,
        product: [],
      };
      break;
    }
    case "ADD_ARRAY_PRICE": {
      cart = {
        ...state,
        array_price: prices,
      };
      break;
    }
    case "ADD_ARRAY_WEIGHT": {
      cart = {
        ...state,
        array_weight: weights,
      };
      break;
    }
    case "RESET_ARRAY_WEIGHT": {
      cart = {
        ...state,
        array_weight: [],
      };
      break;
    }
    case "RESET_ARRAY_PRICE": {
      cart = {
        ...state,
        array_price: [],
      };
      break;
    }
    default:
      cart = state;
      break;
  }
  localStorage.setItem("cart", JSON.stringify(cart));
  return cart;
}

export default reducer;
