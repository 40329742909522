import React from 'react'
import { connect } from 'react-redux'
import './SideBar.css'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

let Sidebar = ({children, onNext, onBack, disabledNext, width, isBottomCustom, bottomCustom}) => {
  return (
    <div className={`d-flex flex-column p-0 ${width} align-items-center sidebar`} style={{ backgroundColor: '#ffffff', minHeight: '100%', maxHeight: '100%' }}>
      <div className='d-flex justify-content-center w-100' style={{ height: 'calc(100% - 56px)', maxHeight: 'calc(100% - 56px)', overflowY: 'auto' }}>
        {children}
      </div>
      {
        !isBottomCustom ? 
          <div className='d-flex flex-row w-100 text-white justify-content-between p-3' style={{ backgroundColor: 'black', marginTop: 'auto' }}>
            <div className='prevButton' onClick={onBack}> <KeyboardArrowLeftIcon style={{ fontSize: '24px' }}/> <span className='sidebar-button'>ANTERIOR</span> </div>
            <div className='nextButton' onClick={!disabledNext ? onNext : () => {}}> <span className='sidebar-button'>SIGUIENTE</span> <KeyboardArrowRightIcon style={{ fontSize: '24px' }}/> </div>
          </div>
        : bottomCustom

      }
    </div>
  )
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({})

const wrapper = connect(mapStateToProps, mapDispatchToProps)
const component = wrapper(Sidebar)

export default component;