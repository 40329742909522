import React from "react";
import { Col, Button, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import './index.css'

const BottomButtons = ({
  onBack,
  onNext,
  onBackLink,
  disabledNext,
  nextLabel,
  lastLabel,
  typeNext,
  typeLast,
  buttonClassName,
  isTermsAndConditions,
  setIsModalOpen
}) => {
  return (
    <Row className="bottom-buttons py-4">
      {isTermsAndConditions && <button onClick={() => setIsModalOpen(true)} className="terms-and-conditions">Términos y Condiciones.</button>}
      <Col className={buttonClassName} >
        <Button
          id={typeNext === "submit" ? "cotizar-button" : ""}
          variant="primary"
          type={typeNext}
          style={{ height: "100%" }}
          className={
            typeNext === "submit"
              ? "btn-block btn-next p-3 square"
              : "btn-block btn-next square"
          }
          onClick={onNext ? onNext : () => {}}
          disabled={disabledNext}>
          {nextLabel ? nextLabel : "Siguiente"}
        </Button>
      </Col>
      <Col className={`${buttonClassName} mt-3`}>
        {onBackLink ? (
          <Link to={onBackLink}>
            <Button
              className={typeNext == "submit" ? "btn-block p-3 square" : "btn-block p-3 square"}
              variant="outline-primary"
              style={{ height: "100%" }}>
              Volver
            </Button>
          </Link>
        ) : lastLabel ? (
          <Button
            className={typeNext == "submit " ? "btn-block p-3 square" : "btn-block p-3 square"}
            variant="outline-primary"
            style={{ height: "100%" }}
            onClick={onBack}
            type={typeLast}>
            {lastLabel ? lastLabel : "Volver"}
          </Button>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
};

export default BottomButtons;
