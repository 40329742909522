import React from "react";
import logo from "../../img/logo.png";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./NavBar.css";

let changeGreengrocers = (history, { params }) => {
  let changeType = params.type === "normal" ? "greengrocer" : "normal";
  window.location.href = `/client/type/${changeType}/open`;
  // history.push(`/client/type/${changeType}/open`)
};

const titles = [
  "",
  "COLECCIÓN DE PUERTAS",
  "MEDIDAS",
  "TIPO DE MECANIZADO",
  "RESUMEN DE TU PEDIDO",
  "CHECKOUT",
];

let Header = ({
  cart,
  showCart,
  withTitle,
  stepNumber,
  redirectTo,
  history,
  match,
}) => {
  let questionChangeDoor =
    match.params.type === "normal" ? " verdulero" : "a puerta";
  return (
    <>
      <Navbar
        bg="white"
        variant="light"
        className="col-12"
        style={{ padding: "15px 35px" }}>
        <Navbar.Brand
          href={"https://hbtsoluciones.cl/"}
          className="pt-0 d-flex align-items-center">
          <img
            src={logo}
            height="50"
            className="d-inline-block align-top"
            alt="hbt logo"
          />
          {withTitle ? (
            <span className="h4 text-primary pl-2 m-0">Puertas y Ventanas</span>
          ) : null}
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          {showCart ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "20px",
              }}>
              <span>
                <a className="underline-hover" target="_blank" href="https://separadores.hbtsoluciones.cl/documents"> Buscar en Historial </a>
              </span>
              <div className="divider"></div>
              <a href="/client/cart/buy" >
                <img src="/cart1.png" style={{ width: "32px" , height : "32px"}} />
              </a>
              <a href="/client/cart/buy" className="rounded-circle div-count-cart text-center text-white">
                    {cart.product.length}
              </a>
            </div>
          ) : null}
        </Navbar.Collapse>
      </Navbar>
      <div
        className="col-12 d-flex flex-row justify-content-between w-100 text-white px-4 py-3 position-relative"
        style={{ backgroundColor: "black" }}>
        {/* <b> {stepNumber}/5 {titles[stepNumber]} </b> */}
        <div className="d-flex position-relative">
          <div className="position-absolute d-flex">
            <div className="step-number text-white"> {stepNumber} </div>
            <div
              style={{
                marginTop: "40%",
                marginLeft: "3px",
                fontWeight: "lighter",
              }}>
              {" "}
              5{" "}
            </div>
          </div>
          <span style={{ paddingLeft: "3rem" }} className="stepper-title"> {titles[stepNumber]} </span>
        </div>
        <b style={{ marginLeft: "auto" }} className="stepper-title"> FRENTES Y PUERTAS DE ALUMINIO </b>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  cart: state.cart,
});

const mapDispatchToProps = (dispach) => ({});

const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(Header);

export default component;
