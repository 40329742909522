const defaultState = 'normal'

function reducer(state = defaultState, {type, door_width, door_height}) {
  let stateValue = '';
  switch (type) {
    case 'CHANGE_SIZE': {
      door_width = parseInt(door_width);
      door_height = parseInt(door_height)
      let width = isNaN(door_width)? 0 : door_width
      let height = isNaN(door_height)? 0 : door_height
      let sizePreview = width===height? 'normal' : width>height?  'horizontal' : 'vertical'
      stateValue = sizePreview
      break;
    }
    case 'RESET_SIZE': {
      stateValue = 'normal'
      break;
    }
    default:
      stateValue = state;
      break;
  }
  return stateValue
}

export default reducer;