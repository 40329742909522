import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./Selected";
// import DesignSize from './DesignSize'

const DesignSizeContainer = ({ ...props }) => {
  const [selectedwidth, setselectedwidth] = useState(0);
  const [selectedHeight, setselectedHeight] = useState(0);

  useEffect(() => {
    let { match, changeSizePreview } = props;
    const selectedwidth = match.params.width;
    const selectedHeight = match.params.height;
    setselectedwidth(selectedwidth);
    setselectedHeight(selectedHeight);
    changeSizePreview(selectedwidth, selectedHeight);
  }, []);

  let { open, type } = props.match.params;
  return (
    <Link to={`/client/type/${type}/open/${open}`} className='breadcrumb-box'>
      <div id="step-text-box">
        <span>Ancho x Altura</span>
        <p className='m-0 pt-1'>
          {selectedwidth} x {selectedHeight} <small>mm</small>
        </p>
      </div>
    </Link>
  );
};

const mapStateToProps = state => ({
  size: state.size
});

const mapDispatchToProps = dispach => ({
  changeSizePreview(door_width, door_height) {
    dispach({
      type: "CHANGE_SIZE",
      door_width,
      door_height
    });
  }
});

const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(DesignSizeContainer);

export default component;
