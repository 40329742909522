const initialState = {
  fillingFetchedData: [], 
};

const reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case "SET_FILLING_RESULT":
      return {
        ...state,
        fillingFetchedData: payload.data,
      };
    default:
      return state;
  }
};

export default reducer;
