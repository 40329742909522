import React from "react";
import "./AddHinge.scss";
import SelectHinge from "./SelectHinge";

export let hinges = [];
class AddHinge extends React.Component {
  constructor() {
    super();
    this.state = {
      id: "divHing",
      openTo: "",
      arrayHinges: [],
      position: "-",
      allValue: [],
      directionOfHinge: "",
      gridAreaText: "",
    };
  }

  componentDidMount() {
    let { open, height, width } = this.props.match.params;
    height = parseInt(height);
    width = parseInt(width);
    let position = width > height ? "horizontal" : width < height ? "vertical" : "normal";
    let arrayGridArea = [];
    let countHinge = [
      { value: 4, section: "A" },
      { value: 5, section: "B" },
      { value: 6, section: "C" },
      { value: 7, section: "D" },
      { value: 8, section: "E" },
    ];
    for (let index = 4; index < 9; index++) {
      arrayGridArea.push(index);
    }
    let maxSide = 9;
    let side = open === "Left" || open === "Top" ? 3 : 9;
    let gridAreaText =
      open === "Bottom" || open === "Right"
        ? `${side} / ${side}`
        : open === "Top"
        ? `${side} / ${maxSide}`
        : `${maxSide} / ${side}`;
    let directionOfHinge =
      open === "Bottom" || open === "Top"
        ? `derecha a izquierda`
        : `abajo hacia arriba`;
    let allValue = countHinge.map((val, key) => {
      return (
        <SelectHinge
          {...this.props}
          side={side}
          openTo={open}
          val={val}
          key={key}
        />
      );
    });
    this.setState({
      directionOfHinge,
      position,
      allValue,
      gridAreaText,
    });
  }

  render() {
    let {
      id,
      position,
      allValue /*, gridAreaText, directionOfHinge*/,
    } = this.state;
    // let { open } = this.props.match.params

    return allValue;
  }
}
export default AddHinge;
