const defaultState = [];

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "ADD_LIST_DOORS": {
      return payload.doors
    }
    case "DELETE_LIST_DOORS": {
      return state.filter((p) => p.id !== payload.id);
    }
    case "RESET_LIST_DOORS": {
      return [];
    }
    default:
      return state;
  }
}

export default reducer;
