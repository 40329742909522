const defaultState = {
  stateValue: false,
  rut: "",
  personalData: {
    price_list: "",
    logistic_train: 'N',
  },
  addresses: [],
};

function reducer(
  state = defaultState,
  { type, personalData, rut, addresses, billing_address }
) {
  switch (type) {
    case "CHANGE_PERSONAL_DATA": {
      return {
        ...state,
        personalData,
      };
    }
    case "CHANGE_RUT": {
      return {
        ...state,
        rut,
      };
    }
    case "CHANGE_STATE_PERSONAL_DATA": {
      return {
        ...state,
        stateValue: true,
      };
    }
    case "RESET_STATE_PERSONAL_DATA": {
      return defaultState;
    }
    case "CHANGE_ADDRESSES": {
      return {
        ...state,
        addresses,
      };
    }
    case "CHANGE_BILLING_ADDRESS":
      return {
        ...state,
        personalData: {
          billing_address: {
            ...state.personalData.billing_address,
            ...billing_address,
          },
        },
      };
    default:
      return { ...state };
  }
}

export default reducer;
