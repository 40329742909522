import React from "react";
import { getMaterial } from "../../api/APIGet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./Selected.scss";
import { formatDescription } from "../../helpers";
class DesignFellingContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      url: "",
      description: "",
    };
  }

  componentDidMount() {
    let { changeUrlFillingPreview, match } = this.props;
    let { nameColor, line } = match.params;
    getMaterial(nameColor).then((response) => {
      let photo_section = response.attributes.photos.filter(
        (p) => p.usage === "FotoTecnica"
      );
      let url = "";
      photo_section.forEach((value) => (url = value.url));
      changeUrlFillingPreview(
        url,
        nameColor,
        line,
        "",
        response.attributes.gloss
      );
      let description = response.attributes.gloss.split(/[\s-]+/);
      description = `${description[0]} ${description[1]} ${description[2]}`;
      this.setState({ url, description });
    });
  }

  render() {
    let { open, type, width, height, mechanized } = this.props.match.params;
    return (
      <Link
        to={`/client/type/${type}/open/${open}/width/${width}/height/${height}/mechanized/${mechanized}`}
      >
        <div className='text-center filling-box-breadcrumbs' xs='12'>
          <div className='d-flex justify-content-center col-12 mt-1'>
            <img
              style={{
                backgroundImage: `url(${this.state.url})`,
                borderRadius: "100%",
                backgroundPosition: "center",
              }}
            />
          </div>
          <span style={{ paddingTop: ".48rem" }}>
            {formatDescription(this.state.description)}
          </span>
        </div>
      </Link>
    );
  }
}

const mapStateToProps = (state) => ({
  filling_url: state.filling_url,
});

const mapDispatchToProps = (dispach) => ({
  changeUrlFillingPreview(url, sku, line, sku_section, description) {
    dispach({
      type: "CHANGE_FILLING",
      url,
      line,
      line_sku: sku_section,
      sku,
      description,
    });
  },
});

const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(DesignFellingContainer);

export default component;
