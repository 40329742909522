export const global = {
  "api": {
    "url": process.env.REACT_APP_API_URL,
    "header": {
      "Content-Type": "application/json",
      "x-api-token": "c0635a85-aee6-4cd4-b777-e42703f21011"
    },
    "header_mpt_fd": {
      "x-api-token": "c0635a85-aee6-4cd4-b777-e42703f21011"
    },
    "header_formdata": {
      "Content-Type": "multipart/form-data",
      "x-api-token": "c0635a85-aee6-4cd4-b777-e42703f21011"
    }
  },
  "api_pim": {
    "url": "https://api.hbt-api-qa.com",
    "header": {
      "Content-Type": "application/json",
      "x-api-token": "c0635a85-aee6-4cd4-b777-e42703f21011"
    },
    "header_mpt_fd": {
      "x-api-token": "c0635a85-aee6-4cd4-b777-e42703f21011"
    },
    "header_formdata": {
      "Content-Type": "multipart/form-data",
      "x-api-token": "c0635a85-aee6-4cd4-b777-e42703f21011"
    }
  }
}
