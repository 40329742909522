import React, { Component } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import Selected from "../selected/Selected";
import { toast } from "react-toastify";
import BottomButtons from "../../components/bottomButtons";
import Preview from "../preview/Preview";
import "./index.scss";
import { Route } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
export const makeid = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const Confirmation = ({ ...props }) => {
  let { hinges, addProductCart, history, location, mechanized, height_furniture='' } = props
  let {
    open, width, height,line,line_sku, nameColor, profile, repeat, type, blackFilm
  } = useParams()
  const getHinges = () => {
    if (hinges === "noHinges") return [];
    else
      return hinges.split("x").map((val) => {
        return { value: val.split("|")[0], section: val.split("|")[1] };
      });
  };

  let addProduct = {
    id: makeid(5),
    side: open,
    base: width,
    height,
    line,
    line_sku,
    filling_sku: nameColor,
    sash_sku: profile,
    hinge_positions: getHinges(),
    hinge_quantity: hinges === "noHinges" ? 0 : hinges.split("x").length,
    repetition: repeat,
    kind: type,
    black_film: blackFilm,
    mechanized: mechanized,
    height_furniture: height_furniture
  };

  let PreviewContainer = (props) => {
    let { type } = props.match.params;
    let name = type === "normal" ? "puerta" : "verdulero";
    return (
      <Row className='justify-content-center'>
        <h4 className='bg-primary mt-3 preview-title  text-white text-center'>
          Esquema de tu {name}
        </h4>
        <Col className='col-lg-12 col-12 py-5 previewBack'>
          <Preview {...props} />
        </Col>
        <Col className='col-12'>
          <h6 className='mt-3 preview-leyend'>*Imagen referencial</h6>
        </Col>
      </Row>
    );
  };

  return (
    <Container className='summary-container'>
      <Row className='justify-content-center'>
        <Col className='offset-lg-1 col-12 col-lg-4 mb-3'>
          <Route
            path='/client/type/:type/open'
            component={(props) => <PreviewContainer {...props} />}
          />
        </Col>
      </Row>
      <Row>
        <BottomButtons
          buttonClassName='col-lg-3 col-6 '
          nextLabel={"Agregar al carro"}
          onBackLink={location.pathname.split("repeat")[0]}
          onNext={() => addProductCart(addProduct, history)}
        />
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  cart: state.cart,
  mechanized: state.mechanized.value,
  height_furniture: state.heightFurniture.height_furniture,
  hinges: state.hinges.hinge_position
});

const mapDispatchToProps = (dispach) => ({
  addProductCart(product, history) {
    history.push(`/client/cart/buy`);
    toast.success("La puerta ha sido añadida al carrito de compras");
    dispach({
      type: "ADD_PRODUCT",
      product,
    });
  },
});

const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(Confirmation);

export default component;
