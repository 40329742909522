import React, { useState, useEffect } from "react";
// import { makeStyles } from '@material-ui/core/styles';
import { Row, Col } from "react-bootstrap";
import InputTextField from "../../../components/InputTextField";
import InputSelectField from "../../../components/InputSelectField";
// import { findClient } from "../../../api/APIGet";
import { allCommunes } from "../../../api/APIGet";
// import { generateItemsCard } from "../listDoors/ListDoorsv2";
import { TextField } from "@material-ui/core";
import { global } from "../../../api/data";
import { connect } from "react-redux";
import { getProductPrices, getProductWeight } from "../../../api/APIGet";

let notRepeatRequest = false;
let count = 0;
let key_ref = true;

const PersonalData = ({ changeRut, changeBillingAddress, ...props }) => {
  const { format } = require("rut.js");
  const [addresses, setaddresses] = useState([]);
  const [billing_address, setbilling_address] = useState({
    address: "",
    email: "",
    telephone: "",
  });
  const setEmail = (value) => {
    setbilling_address({ ...billing_address, email: value });
  };
  const setNumber = (value) => {
    setbilling_address({ ...billing_address, telephone: value });
  };
  const setaddress = (value) => {
    setbilling_address({ ...billing_address, address: value });
  };
  const [business_name, setbusiness_name] = useState("");
  const [legal_code, setlegal_code] = useState("");
  const [business_activity, setbusiness_activity] = useState("");
  const [nameCommunes, setnameCommunes] = useState([]);
  const [rut, setRut] = useState("");
  const [mainEmail, setMainEmail] = useState("")
  const [secondEmail, setSecondEmail] = useState("");
  const [rutTimeout, setRutTimeout] = useState(null);
  const [priceList, setPriceList] = useState('TIENDA')

  const findClient = (ref) => {
    let url = `${global.api.url}/clients/find?ref=${ref}`;
    return fetch(url, {
      method: "GET",
      headers: global.api.header,
    })
      .then((response) => response.json())
      .catch((error) => console.error("Error json:", error));
  };

  const setProductPrices = async () => {
    let publicPrices = [];
    let productsWeight = [];
    await Promise.all(
      props.cart.product.map(async (door) => {
        const prices = await getProductPrices(door.line_sku);
        let weight = await getProductWeight(door.line_sku);
        let publicPrice = prices.filter((p) => {
          return p.name === priceList;
        });
        let publicPriceValue =
          publicPrice.length !== 0 ? parseInt(publicPrice[0].value) : 1;
        let publicPriceTotal = parseInt(door.repetition) * publicPriceValue;


        productsWeight.push(+weight * +door.repetition);
        publicPrices.push({
          publicPrice: publicPriceValue,
          publicPriceTotal: publicPriceTotal,
          door_id: door.id,
        });
      })
    );
    props.addWeightsCart(productsWeight)
    props.addPriceListCar(publicPrices);
  };

  const getPrices = (line_sku, repeat) => {
    getProductPrices(line_sku).then((response) => {
      let publicPrice = response.attributes.prices.filter((p) => {
        return p.name === props.personalData.price_list;
      });
      let publicPriceValue =
        publicPrice.length !== 0 ? parseInt(publicPrice[0].value) : 1;
      let publicPriceTotal = parseInt(repeat) * publicPriceValue;

      props.addPriceListCar(publicPriceTotal);
      return {
        publicPrice: publicPriceValue,
        publicPriceTotal,
      };
    });
  };

  const autocompleteClientData = (ref) => {
    props.resetStatePersonalData();
    findClient(ref).then((response) => {
      let ref_legth = ref.length;
      if (response !== null) {
        let {
          changeStatePersonalData,
          resetStatePersonalData,
          changePersonalData,
        } = props;
        changePersonalData(response);
        props.changeAddresses(response.addresses ? response.addresses : []);
        setbilling_address(
          response.billing_address
            ? response.billing_address
            : { address: "", email: "", telephone: "" }
        );
        setMainEmail(response.billing_address['email'] ? response.billing_address['email'] : '' )
        setSecondEmail(response.billing_address['email'] ? response.billing_address['email'] : '');
        setbusiness_name(response.business_name ? response.business_name : "");
        setlegal_code(response.business_name ? response.business_name : "");
        setbusiness_activity(
          response.business_activity ? response.business_activity : ""
        );

        if (response.billing_address !== null) {
          if (response.billing_address.commune !== undefined) {
            props.changeCommuneValue(response.billing_address.commune_id);
          }
        }
        setPriceList(response.price_list)
      }

    });

      };

  useEffect(() => {
    allCommunes().then((response) => {
      if(response){
        let communes = response.map((val) => {
          return { name: val.id, value: val.name };
        });
        setnameCommunes(communes);
      }
      
    });
    // generateItemsCard(props);
  }, []);

  useEffect(() => {
    props.resetPriceListCar();
    setProductPrices();
  }, [priceList]);

  const onChangeRut = (e) => {
    setRutTimeout(clearTimeout(rutTimeout));
    // Make a new rutTimeout set to go off in 1000ms (1 second)
    setRut(format(e.target.value));
    const rutValue = e.target.value;
    setRutTimeout(
      setTimeout(() => {
        changeRut(format(rutValue));
        autocompleteClientData(format(rutValue));
      }, 800)
    );
  };

  let { address, email, telephone } = billing_address;

  return (
    <Row>
      <span className='text-left col-12' style={{ fontWeight: 'bold' }}>1. DATOS DEL CLIENTE</span>
      <Col sm='12' md='6' lg='6' className="mt-3">
        <InputTextField
          id='invoice_ref_id'
          title='Rut'
          placeholder={"Ej: 21886629-8"}
          value={rut}
          onChange={onChangeRut}
          disabled={false}
          name='ref'
          type='text'
        />
      </Col>
      <Col sm='12' md='6' lg='6' className="mt-3">
        <InputTextField
          id='business_activity_id'
          title='Giro'
          placeholder='Giro (si es persona natural colocar particular)'
          value={business_activity}
          name='business_activity'
          disabled={legal_code !== ""}
          onChange={(e) => setbusiness_activity(e.target.value)}
          type='text'
        />
      </Col>
      <Col sm='12' md='6' lg='6' className="mt-3">
        <InputTextField
          id='client_name_id'
          title='Nombre y Apellido'
          placeholder={'Antony Bocanegra'}
          value={business_name}
          name='business_name'
          disabled={legal_code !== ""}
          onChange={(e) => setbusiness_name(e.target.value)}
          type='text'
        />
      </Col>
      <Col sm='12' md='6' lg='6' className="mt-3">
        <InputTextField
          id='client_email_id'
          title='Correo electrónico'
          placeholder='EJ: micorreo@ejemplo.cl'
          value={email}
          name='email'
          disabled={mainEmail !== ""}
          onChange={(e) => setEmail(e.target.value)}
          type='email'
        />
      </Col>
      <Col sm='12' md='6' lg='6' className="mt-3">
        <InputTextField
          id='client_phone_id'
          title='Teléfono'
          placeholder='Ej: +56963402838'
          value={telephone}
          name='phone'
          disabled={legal_code !== ""}
          onChange={(e) => setNumber(e.target.value)}
          type='number'
        />
      </Col>
      <Col sm='12' md='6' lg='6' className="mt-3">
        <InputTextField
          id='client_second_email_id'
          title='Correo electrónico alternativo'
          placeholder='EJ: micorreo@ejemplo.cl'
          value={secondEmail}
          name='second_mail'
          onChange={(e) => setSecondEmail(e.target.value)}
          type='email'
        />
      </Col>
      <Col sm='12' md='6' lg='6' className="mt-3">
        <Col sm='12' md='12' lg='12'>
          <InputSelectField
            id='client_comune_id'
            title='Comuna'
            placeholder='Seleccione por favor su comuna'
            name='comune'
            options={nameCommunes}
            disabled={legal_code !== ""}
          />
        </Col>
      </Col>
      <Col sm='12' md='6' lg='6' className="mt-3">
        <InputTextField
          id='client_address_id'
          title='Dirección'
          placeholder='Ej: Av. Calle 123, Depto A21'
          value={address}
          name='street_addr'
          disabled={legal_code !== ""}
          onChange={(e) => setaddress(e.target.value)}
          type='text'
        />
      </Col>
      {/* <Col md='12'>
        <InputTextField
          id='client_second_email_id'
          title='Enviar cotización al correo electrónico'
          name='second_mail'
          value={secondEmail}
          onChange={(e) => setSecondEmail(e.target.value)}
          type='email'
        />
      </Col> */}
    </Row>
  );
};

const mapStateToProps = (state) => ({
  cart: state.cart,
  personalData: state.statePersonal.personalData,
  listDoorsItems: state.listDoorsItems,
});

const mapDispatchToProps = (dispatch) => ({
  changeRut(rut) {
    dispatch({
      type: "CHANGE_RUT",
      rut,
    });
  },
  changeCommuneValue(change_state) {
    dispatch({
      type: "CHANGE_STATE_SELECT_COMMUNE",
      change_state,
    });
  },
  changePersonalData(personalData) {
    dispatch({
      type: "CHANGE_PERSONAL_DATA",
      personalData,
    });
  },
  changeStatePersonalData() {
    dispatch({
      type: "CHANGE_STATE_PERSONAL_DATA",
    });
  },
  resetStatePersonalData() {
    dispatch({
      type: "RESET_STATE_PERSONAL_DATA",
    });
  },
  resetItemOfCar() {
    dispatch({
      type: "RESET_LIST_DOORS",
    });
  },
  addPriceListCar(prices) {
    dispatch({
      type: "ADD_ARRAY_PRICE",
      prices,
    });
  },
  addWeightsCart(weights) {
    dispatch({
      type: "ADD_ARRAY_WEIGHT",
      weights,
    });
  },
  resetPriceListCar() {
    dispatch({
      type: "RESET_ARRAY_PRICE",
    });
  },
  addDoorsCar(doors) {
    dispatch({
      type: "ADD_LIST_DOORS",
      payload: { doors },
    });
  },
  changeAddresses(addresses) {
    dispatch({
      type: "CHANGE_ADDRESSES",
      addresses,
    });
  },
  changeBillingAddress(billing_address) {
    dispatch({
      type: "CHANGE_BILLING_ADDRESS",
      billing_address,
    });
  },
});

const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(PersonalData);

export default component;
