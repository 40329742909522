import React, { Component, useEffect } from "react";
import { TextField } from "@material-ui/core";

const InputTextField = ({
  id,
  name,
  title,
  value,
  disabled,
  type,
  onChange,
  placeholder
}) => {
  value = value === undefined ? `` : value;

  return (
    <div className="d-flex flex-column col-12 align-items-start">
      <p className="m-0 mb-2"> {title} </p>
      <TextField
        id={id}
        name={name}
        label=''
        variant='outlined'
        fullWidth
        margin='normal'
        size="medium"
        value={value}
        disabled={disabled}
        required={true}
        type={type}
        className="m-0"
        inputProps={{
          maxLength: id === "invoice_ref_id" ? 12 : 200,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

export default InputTextField;
