const defaultState = {
  url: "",
  line: "",
  line_sku: "",
  sku: "",
  canGreengrocer: false,
};

function reducer(
  state = defaultState,
  { type, url, line, line_sku, sku, film, description, ...payload }
) {
  switch (type) {
    case "CHANGE_FILLING": {
      return { ...state, url, line, line_sku, sku, description };
    }
    case "RESET_FILLING": {
      return { ...state, url: "", line: "", line_sku: "", sku: "", film: false };
    }
    case "CHANGE_FILM": {
      return { ...state, film }
    }
    case "CHANGE_STATE_GREENGROCER": {
      return {
        ...state,
        canGreengrocer: payload.opt,
      };
    }
    default:
      return state;
  }
}

export default reducer;
